import React, { FocusEventHandler } from 'react'
import get from 'lodash/get'
import find from 'lodash/find'
import { Button } from 'components/shared/Button'
import TextField from '@material-ui/core/TextField'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import Chip from '@material-ui/core/Chip'
import Tooltip from '@material-ui/core/Tooltip'
import { OptionProps } from 'components/shared/OptionGroup'
import { FormLabel } from 'components/shared/OptionGroup/styled'
import { UseGetOptions } from 'components/shared/FilterMenu/types'
import * as S from './styled'
import useFilterMenuOpenCloseState from 'hooks/useFilterMenuOpenCloseState'

function AutocompleteContainer(props: AutocompleteProps) {
  if (props.useGetOptions) {
    return <AutocompleteGetOptions {...props} />
  }
  return <Autocomplete {...props} />
}

function AutocompleteGetOptions({ useGetOptions, filterOutOption, ...props }: AutocompleteProps) {
  const { isLoading, error, options } = (useGetOptions as UseGetOptions)({
    realtimeDashboardID: props.realtimeDashboardID,
  })

  if (isLoading) {
    return (
      <S.AppVersionFormControl component="fieldset">
        <FormLabel>{props.label}</FormLabel>
        <S.StyledLoadingSpinner data-test="autocompleteCircularProgress" small />
      </S.AppVersionFormControl>
    )
  }

  if (error) {
    return (
      <S.ErrorMessage data-test="autocompleteErrorMessage">
        Could not load filter: {props.label}.
      </S.ErrorMessage>
    )
  }

  const filteredOptions = filterOutOption
    ? options.filter((option?: OptionProps) => option !== filterOutOption)
    : options
  return <Autocomplete data-test="autocompleteGetOptions" {...props} options={filteredOptions} />
}

function Autocomplete({
  label,
  options,
  value,
  defaultValue,
  onChange,
  getOptionLabel,
  placeholder,
  canSelectMultipleValues = true,
  disableCloseOnSelect = false,
  width,
  onBlur,
  fullHeight = false,
  fullWidth = false,
  onInputChange,
  noOptionsText,
  isLoading = false,
  showTagTooltip = false,
  isSequenceEventTypeFilter = false,
  invertedLabel,
  groupBy,
  freeSolo = true,
  renderOption,
  textFieldIdentifier,
  disablePortal,
  filterOptions,
  disabled,
  noSpecialCharactersAllowed,
}: AutocompleteProps) {
  const { setDisableDragging } = useFilterMenuOpenCloseState()
  function handleChange(e: any, newVal: any, reason: string, detail?: { option: string }) {
    const newValuesToChange = Array.isArray(newVal)
      ? newVal.map((value: OptionProps) => get(value, 'value', value))
      : newVal

    if (isSequenceEventTypeFilter) {
      if (reason === 'clear') {
        return
      }
      const invertedNewValues = options
        ?.filter((option: OptionProps) => {
          const optionValue = get(option, 'value', option)
          return !newValuesToChange?.includes(optionValue)
        })
        .map((value: OptionProps) => get(value, 'value', value))

      onChange(invertedNewValues, reason, detail)
    } else {
      onChange(newValuesToChange, reason, detail)
    }
  }

  function defaultGetOptionLabel(option: { label: string }) {
    return option?.label ?? option
  }

  function getDefaultInvertedValue() {
    // Filters options based on selected filters
    const formattedDefaultValues = options?.filter((option: OptionProps) => {
      const optionValue = get(option, 'value', option)
      return !defaultValue?.includes(optionValue)
    })
    return formattedDefaultValues?.map((value: OptionProps) => get(value, 'value', value))
  }

  const handleSelectAllEventTypes = React.useCallback(() => onChange([]), [onChange])

  const handleDropdownBlur = () => {
    if (setDisableDragging) setDisableDragging(false)
  }
  const handleDropdownFocus = () => {
    if (setDisableDragging) setDisableDragging(true)
  }

  return (
    <S.AppVersionFormControl component="fieldset" fullWidth={fullWidth} width={width}>
      {label && (
        <FormLabel data-test="autocompleteLabel">
          {isSequenceEventTypeFilter ? invertedLabel : label}
        </FormLabel>
      )}
      <S.AutocompleteContainer $disablePortal={disablePortal}>
        <S.StyledAutocomplete
          multiple={canSelectMultipleValues}
          filterSelectedOptions
          disableCloseOnSelect={disableCloseOnSelect}
          options={options}
          getOptionSelected={(option: any, value: any) =>
            get(option, 'value', option) === get(value, 'value', value)
          }
          getOptionLabel={getOptionLabel || defaultGetOptionLabel}
          renderOption={renderOption}
          size="small"
          defaultValue={
            isSequenceEventTypeFilter
              ? getDefaultInvertedValue
              : defaultValue
                ? defaultValue
                : undefined
          }
          disableListWrap
          disablePortal={disablePortal}
          value={value}
          onChange={handleChange}
          onInputChange={onInputChange}
          width={width}
          fullHeight={fullHeight}
          fullWidth={fullWidth}
          noOptionsText={noOptionsText}
          disabled={disabled || isLoading}
          freeSolo={freeSolo}
          forcePopupIcon
          popupIcon={
            <ArrowDropDownIcon
              data-pho={`${textFieldIdentifier ? textFieldIdentifier : ''}popUpIcon`}
            />
          }
          groupBy={groupBy}
          ListboxProps={{ id: 'autocomplete-menu', 'data-pho': 'autocompleteMenu' }}
          renderTags={(value: any, getTagProps: any) => (
            <S.ChipContainer>
              {Array.isArray(value) &&
                value?.map((option: any, index: number) => {
                  const optionValue = get(option, 'value', option)
                  const optionInfo = find(options, { value: optionValue })
                  const optionLabel = get(optionInfo, 'label', optionValue)
                  return showTagTooltip ? (
                    <Tooltip title={optionLabel}>
                      <Chip
                        data-test="autocompleteTagChip"
                        key={optionLabel}
                        size="small"
                        label={optionLabel}
                        {...getTagProps({ index })}
                      />
                    </Tooltip>
                  ) : (
                    <Chip
                      data-test="autocompleteTagChip"
                      key={optionLabel}
                      size="small"
                      label={optionLabel}
                      {...getTagProps({ index })}
                    />
                  )
                })}
            </S.ChipContainer>
          )}
          data-test="styledAutocomplete"
          renderInput={(params: any) => {
            const newParam = noSpecialCharactersAllowed
              ? {
                ...params,
                inputProps: {
                  ...params.inputProps,
                  value: params.inputProps.value?.toString().replace(/[^\w\s]/gi, ''),
                },
              }
              : params

            return (
              <TextField
                {...newParam}
                inputProps={{
                  ...newParam.inputProps,
                  onKeyDown: e => {
                    if (e.key === 'Enter') {
                      e.stopPropagation()
                    }
                  },
                }}
                variant="outlined"
                data-test={`autocompleteTextField_${
                  isSequenceEventTypeFilter ? invertedLabel : label
                  }`}
                data-pho={`${textFieldIdentifier ? textFieldIdentifier : ''}autocompleteTextField`}
                placeholder={isLoading ? 'Loading...' : placeholder ?? 'Search'}
              />
            )
          }}
          filterOptions={filterOptions ? filterOptions : undefined}
          onFocus={handleDropdownFocus}
          onOpen={handleDropdownFocus}
          onClose={handleDropdownBlur}
        />
        {isSequenceEventTypeFilter && (
          <Button width="100%" variant="borderless" onClick={handleSelectAllEventTypes}>
            Select All Event Types
          </Button>
        )}
      </S.AutocompleteContainer>
    </S.AppVersionFormControl>
  )
}

interface AutocompleteProps {
  label?: string
  value?: any
  defaultValue?: any
  onChange: (args?: any, reason?: any, details?: { option: string }) => void
  options?: OptionProps[]
  useGetOptions?: UseGetOptions
  getOptionLabel?: (arg: any) => string
  renderOption?: (option: { label: string }) => JSX.Element
  placeholder?: string
  canSelectMultipleValues?: boolean
  disableCloseOnSelect?: boolean
  realtimeDashboardID?: string
  width?: string
  onKeyDown?: any
  filterOutOption?: string
  onInputChange?: (event: object, value: string, reason: string) => void
  noOptionsText?: string
  isLoading?: boolean
  fullHeight?: boolean
  showTagTooltip?: boolean
  isSequenceEventTypeFilter?: boolean
  invertedLabel?: string
  fullWidth?: boolean
  groupBy?: Function
  textFieldIdentifier?: string
  freeSolo?: boolean
  disablePortal?: boolean
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
  filterOptions?: (
    options: Record<string, string>[],
    { inputValue }: { inputValue: string }
  ) => void
  disabled?: boolean
  noSpecialCharactersAllowed?: boolean
}

export default AutocompleteContainer
