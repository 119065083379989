import { useState } from 'react'
import { Method } from 'axios'
import { services } from 'common/services'
import { rootApiUrl } from 'common/constants'
import { axios, axiosConfigBase } from 'hooks/useApi'
import { Toast } from 'components/shared/Toast'
import Link from 'components/shared/Link/PrismReactLink'
import { copyToClipboard } from 'utils'
import ButtonIconWithTooltip from './ButtonIconWithTooltip'
import Tooltip from '@material-ui/core/Tooltip'
import * as S from '../styles'
import Highlighter from 'react-highlight-words'

function AccountNumberTableCell({
  accountNumber,
  decryptedAccountNumber,
  setDecryptedAccountNumber,
  hasTransformerDecryptAccess,
  textToSearch,
  HighlightElement
}: AccountNumberTableCellProps) {
  const [value, setValue] = useState(accountNumber)
  const [error, setError] = useState('')
  const [copiedValue, setCopiedValue] = useState(accountNumber)
  const [open, setOpen] = useState(false)

  const isDecrypted = Boolean(decryptedAccountNumber && value && decryptedAccountNumber === value)
  const valueToTransform = isDecrypted ? value : accountNumber
  const cryptingMethod = isDecrypted ? 'encrypt' : 'decrypt'

  function handleCopyToClipboard(valueToCopy: string) {
    copyToClipboard(valueToCopy)
    setCopiedValue(valueToCopy)
    setOpen(true)
  }

  async function transformData(shouldCopy: boolean) {
    const axiosConfig = {
      ...axiosConfigBase(),
      method: 'POST' as Method,
      data: {
        data: [{ field: cryptingMethod, value: valueToTransform }],
        cryptingMethod,
      },
      url: `${rootApiUrl}${services.transformer.url}`,
    }

    const response = await axios({ ...axiosConfig })
    const result = response?.data[0]?.value

    // this way of catching error is used because Transformer API returns error with 200 status and error text in value
    if (result.includes('Spec Guide decryption failed')) {
      setError(
        `Unable to ${cryptingMethod} account number. Refresh the page and try again. If the error persists, click here to Report an Issue.`
      )
    } else {
      setValue(result)
      setDecryptedAccountNumber(cryptingMethod === 'decrypt' ? result : '')
      if (shouldCopy) {
        handleCopyToClipboard(cryptingMethod === 'decrypt' ? result : accountNumber)
      }
    }
  }

  const copyOrDecrypt = isDecrypted
    ? `Copy to clipboard: ${value}`
    : `Decrypt account number and copy to clipboard`
  const copyDecryptPermissionsText = hasTransformerDecryptAccess
    ? copyOrDecrypt
    : 'Transformer Decrypt permissions are required to decrypt account numbers.'
  const checkPermissionsText = hasTransformerDecryptAccess
    ? ''
    : 'Transformer Decrypt permissions are required to decrypt account numbers.'

  if (error) {
    return (
      <Link
        href="https://jira.charter.com/secure/CreateIssue.jspa?pid=74201&issuetype=1"
        variant="standalone"
        newTab
        data-pho="linkNewBugTicket"
      >
        <h6>Error: {error}</h6>
      </Link>
    )
  }

  return (
    <>
      <S.CopyButtonContainer>
        <S.CopyValueContainer>
          <S.CopyValueLabel>Encrypted</S.CopyValueLabel>
          <Highlighter
            highlightTag={HighlightElement}
            searchWords={[textToSearch ?? '']}
            autoEscape={true}
            textToHighlight={accountNumber ? accountNumber : ''}
          />
        </S.CopyValueContainer>
        <ButtonIconWithTooltip
          title={`Copy to Clipboard: ${accountNumber}`}
          buttonText=""
          icon="Copy"
          iconSize="sm"
          variant="borderless"
          onClick={handleCopyToClipboard}
          onClickParams={[accountNumber]}
          data-pho={`copyToClipBoard-${accountNumber}`}
        />
      </S.CopyButtonContainer>
      <S.CopyButtonContainer>
        {isDecrypted ? (
          <>
            <S.CopyValueContainer>
              <S.CopyValueLabel data-pho="accountNumberDecryptedRevealed">
                Decrypted
              </S.CopyValueLabel>
              {value}
            </S.CopyValueContainer>
            <S.CopyValueActions>
              <ButtonIconWithTooltip
                title=""
                buttonText=""
                icon="EyeCross"
                iconSize="sm"
                variant="borderless"
                onClick={transformData}
                onClickParams={[false]}
                disabled={!hasTransformerDecryptAccess}
              />
              <ButtonIconWithTooltip
                title={copyDecryptPermissionsText}
                buttonText=""
                icon="Copy"
                iconSize="sm"
                variant="borderless"
                onClick={handleCopyToClipboard}
                onClickParams={[value]}
                disabled={!hasTransformerDecryptAccess}
              />
            </S.CopyValueActions>
          </>
        ) : (
          <>
            <S.CopyValueContainer>
              <S.CopyValueLabel>Decrypted</S.CopyValueLabel>
              <Tooltip title={checkPermissionsText} placement="top" arrow>
                <div data-pho="accountNumberDecryptedHidden">
                  {Array.from(accountNumber).fill('•').join('')}
                </div>
              </Tooltip>
            </S.CopyValueContainer>
            <S.CopyValueActions>
              <ButtonIconWithTooltip
                title={checkPermissionsText}
                buttonText=""
                icon="Eye"
                iconSize="sm"
                variant="borderless"
                onClick={transformData}
                onClickParams={[false]}
                disabled={!hasTransformerDecryptAccess}
              />
              <ButtonIconWithTooltip
                title={copyDecryptPermissionsText}
                buttonText=""
                icon="Copy"
                iconSize="sm"
                variant="borderless"
                onClick={transformData}
                onClickParams={[true]}
                disabled={!hasTransformerDecryptAccess}
              />
            </S.CopyValueActions>
          </>
        )}
      </S.CopyButtonContainer>
      <Toast
        placement="bottom-left"
        isToastOpen={open}
        onDismiss={() => setOpen(false)}
        duration={6}
        label={`Copied to Clipboard: ${copiedValue}`}
        data-pho="copiedToClipboardSnackbar"
      />
    </>
  )
}

interface AccountNumberTableCellProps {
  accountNumber: string
  decryptedAccountNumber: string
  setDecryptedAccountNumber: Function
  hasTransformerDecryptAccess: boolean
  textToSearch?: string
  HighlightElement?: any
}

export default AccountNumberTableCell
