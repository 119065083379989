import styled from 'styled-components'
import TablePagination from '@material-ui/core/TablePagination'
import TextField from '@material-ui/core/TextField'
import Input from '@material-ui/core/Input'
import TableCell from '@material-ui/core/TableCell'
import { Select } from '@material-ui/core'
import * as MuiTableContainer from '@material-ui/core/TableContainer'
import { Card } from 'components/shared/Card'
import { colors } from 'components/shared/utils/colors'
import { Switch } from 'components/shared/Switch'
import { Dropdown } from 'components/shared/Dropdown'
import Typography from 'components/shared/typography/Typography'
import ShareIcon from '@material-ui/icons/Share'
import GetAppIcon from '@material-ui/icons/GetApp'
import QueueIcon from '@material-ui/icons/Queue'
import { Rnd } from 'react-rnd'

export const StyledVerbatimTableCell = styled(TableCell)`
  overflow-wrap: anywhere;
  white-space: pre-wrap;
`

export const TableCellColumnWidth = styled.div<{ width?: number }>`
  width: ${props => props.width}px;
`

export const ChartContainer = styled.div`
  width: 100%;
  margin-bottom: 1rem;
`

export const MultipleTableSorts = styled.div`
  display: flex;
  justify-content: space-between;
`

export const TableLoadingSpinnerContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const TableContainer = styled(Card)`
  width: calc(100vw - 20rem);
  min-width:calc(100vw - 20rem)
  margin-bottom: 3rem;
  overflow-x: auto; 
`

export const TimestampContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
`

export const StyledTablePagination = styled(TablePagination)`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;

  && > div > p {
    margin-bottom: 0px;
  }
`

export const TableActions = styled.div`
  padding: 1rem 0rem 0rem 1rem;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  overflow: auto;
  > div > div:first-of-type {
    max-width: 15rem;
  }
`

export const ClickActionsContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`

export const ViewColumnsContainer = styled.div`
  padding: 1rem;

  > *:first-child {
    margin-top: 0;
  }
  > *:last-child {
    margin-bottom: 0;
  }
`

export const ViewColumnHeader = styled.p`
  font-size: 16px;
  font-weight: bold;
  margin: 0.5rem 0;
  padding: 0.25rem;
  background-color: ${colors.gray10};
`

export const ViewColumnGroup = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 12px;
`

export const CategoryTableCellContainer = styled.div<{ hasMultiple?: boolean }>`
  &&:first-of-type {
    padding-bottom: ${({ hasMultiple }) => (hasMultiple ? '0.5rem' : '0rem')};
  }
`

export const CategoriesCellContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
`

export const CategoryTableCellCategory = styled.div`
  font-size: 1rem;
  font-weight: 500;
`

export const CategoryTableCellSubcategory = styled.div`
  font-size: 0.75rem;
`

export const SentimentChartContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  height: 9rem;
  width: 100%;
`

export const SentimentChartContent = styled.div`
  width: 80%;
`

export const ModalField = styled.div`
  width: 100%;
  padding: 0.75rem;
`
export const ModalFieldLabel = styled.div`
  text-align: center;
  margin: 0 auto;
  width: 100%;
`

export const ConfirmationModalFieldLabel = styled.div`
  margin: 0 auto;
  width: 100%;
`

export const ModalInput = styled(TextField)`
  margin-top: 1.4rem;
  width: 100%;
  && > div > textarea {
    color: ${({ error }) => (error ? colors.red10 : 'unset')};
  }
`

export const CategoryModalInput = styled(TextField)`
  width: 100%;
  && > div > textarea {
    color: ${({ error }) => (error ? colors.red10 : 'unset')};
  }
`

export const SearchField = styled(Input)`
  cursor: pointer;
`

export const CopyButtonContainer = styled.div`
  padding: 0.1rem 0.25rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

export const CopyValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

export const CopyValueLabel = styled.div`
  font-size: 0.6rem;
  color: ${colors.gray30};
`

export const CopyValueActions = styled.div`
  display: flex;
  min-width: 15%;
  justify-content: space-between;
`

export const ActionCell = styled(TableCell)`
  > div:first-of-type {
    margin-bottom: 1rem;
  }
`

export const CategoryTableCell = styled.div<{ width?: number }>`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: ${props => props.width}px;
`

export const StyledEditLink = styled.div`
  margin-right: 0.75rem;
`

export const SearchContainer = styled.div`
  min-width: 10%;
  display: flex;
  align-items: center;
`

export const StyledSwitch = styled(Switch)`
  margin-right: 2rem;
`

export const StyledDropdown = styled(Dropdown)`
  width: 100%;
  margin-bottom: 1rem;
`

export const StyledSelect = styled(Select)`
  width: 100%;
  margin-bottom: 1rem;
`

export const CategorySelectContainer = styled.div`
  text-align: start;
  && > div {
    width: 100%;
  }
`

export const MiddleLevelCategorySelectContainer = styled.div`
  margin-top: 1.4rem;
  text-align: start;
  && > div {
    width: 100%;
  }
`

export const StyledTypography = styled(Typography)`
  font-weight: 500;
`

export const StyledTypographyTL = styled(Typography)`
  font-weight: 500;
  margin-bottom: 25px;
`

export const HelpActionContainer = styled.div`
  font-size: 0.75rem;
  position: absolute;
`

export const ArchiveContainer = styled.div`
  margin-top: 20px;
`

export const ReviewTableContainer = styled(MuiTableContainer.default)`
  max-height: 750px;
`
export const Link = styled.span`
  color: ${colors.blue10};
  white-space: nowrap;
`

export const UrlContainer = styled.div`
  display: flex;
  flex-flow: column;
  gap: .5rem;
`
export const ButtonDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

export const DownloadIcon = styled(GetAppIcon)`
  margin: 0.5rem;
`

export const ShareSurvIcon = styled(ShareIcon)`
  margin: 0.5rem;
`

export const VisitIdIcon = styled(QueueIcon)`
  margin: 0.5rem;
`

export const RndPanel = styled(Rnd)`
  && {
    margin: 0;
  }
`

export const NoteTableContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

export const NoteTbleAction = styled.div`
 display: flex;
 flex-direction: column;
`

export const StyledTextarea = styled.textarea<{ width?: number }>`
  border: none; 
  padding: 10px;
  font-size: 16px; 
  width: ${props => props.width}px;
  height: 100px;
  background-color: #F0F0F0;
  outline: none; 
`;

export const NoteActionButton = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 16px
`

export const StyledHighlightWrapper = styled.span`
  background-color: ${colors.yellow10};
`

export const StyledHighlightContent = styled.span`
  color: ${colors.black};
  font-weight: 700;
`


export const DeleteModalActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const CSVDownloadContainer = styled.div`
  display: flex;
  justifycontent: start;
  align-items: center;
  height: 3vh;
`

export const LoaderContainer = styled.span`
  width: 50px;
`