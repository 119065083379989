import { useEffect, useState } from 'react'
import useAuth from './useAuth'

export const useTopTalkerAccess = () => {
  const [hasTopTalkerAccess, setHasTopTalkerAccess] = useState(false)
  const auth = useAuth()
  const roles = auth.user?.roles
  const approvedRoles = ['prism-admin', 'top-talker-user']
  useEffect(() => {
    if (roles) {
      setHasTopTalkerAccess(roles?.some(role => approvedRoles.includes(role)))
    }
  }, [roles])

  return { hasTopTalkerAccess }
}
