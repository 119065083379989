import React from 'react'
import { keys } from 'lodash'
import RowCell from './RowCell'
import * as S from './styles'

function getTextAlignment(index: number, length: number) {
  if (index === 0) {
    return 'left'
  } else if (index === length - 1) {
    return 'right'
  } else {
    return 'center'
  }
}

function TopChart(props: TopChartProps) {
  const {
    data,
    showTitle,
    boldContentPath,
    rowItemClick,
    rowItemIsClickable,
    columnWidths,
    filterValuesToTrim,
  } = props
  const titles = keys(data[0])

  return (
    <S.TopChartContainer>
      {showTitle && (
        <S.TopChartRow>
          {titles.map((title, index) => {
            const width = (columnWidths && columnWidths[index]) || '100%'
            return (
              <S.TopChartTitle key={index} textAlign={getTextAlignment(index, titles.length)} width={width}>
                {title}
              </S.TopChartTitle>
            )
          })}
        </S.TopChartRow>
      )}
      {data.map((row: any, rowIndex: number) => {
        const rowKeys = keys(row)
        const showRegularBold = rowKeys.some((key: any, index: number) => {
          return !(
            boldContentPath &&
            (!boldContentPath[index] || row[key] === boldContentPath[index])
          )
        })
        return (
          <S.TopChartRow
            key={rowIndex}
            data-pho="TopChartRow"
            showBold={!showRegularBold}
            backgroundGreyOnHover={rowItemClick && rowItemIsClickable}
            onClick={() => rowItemClick && rowItemIsClickable && rowItemClick(row[rowKeys[0]], row)}
          >
            {rowKeys.map((rowKey, index) => {
              const rowContent =
                typeof row[rowKey] === 'number' ? row[rowKey].toLocaleString('en-US') : row[rowKey]
              const width = columnWidths ? columnWidths[index] : '100%'

              return (
                <RowCell
                  key={index}
                  textAlign={getTextAlignment(index, rowKeys.length)}
                  width={width}
                  rowContent={rowContent}
                  trimValue={filterValuesToTrim?.includes(rowKey)}
                />
              )
            })}
          </S.TopChartRow>
        )
      })}
    </S.TopChartContainer>
  )
}

interface TopChartProps {
  data: any
  showTitle?: boolean
  boldContentPath?: Array<string>
  rowItemClick?: Function
  rowItemIsClickable?: boolean
  columnWidths?: Array<string>
  filterValuesToTrim?: string[]
}

export default TopChart
