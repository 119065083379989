import React from 'react'
import isEqual from 'lodash/isEqual'
import map from 'lodash/map'
import filter from 'lodash/filter'
import uniq from 'lodash/uniq'
import numeral from 'numeral'
import useApi from 'hooks/useApi'
import { useLocation } from '@reach/router'
import queryString from 'query-string'
import services from 'common/services'
import useGroups from 'hooks/useGroups'
import useApps from 'hooks/useApps'
import { OptionProps } from 'components/shared/OptionGroup'
import * as T from './types'
import { inputTypes } from 'utils/sharedUtils'
import { getDefaultTimeFilterRange } from 'utils/times/times'
import { isEmpty, isNil } from 'lodash'

export enum PRIORITY_LEVEL {
  CRITICAL = 'P0',
  HIGH = 'P1',
  MEDIUM = 'P2',
  LOW = 'P3',
}

const stateList = [
  'Alabama',
  'Alaska',
  'American Samoa',
  'Arizona',
  'Arkansas',
  'California',
  'Colorado',
  'Connecticut',
  'Delaware',
  'District Of Columbia',
  'Federated States Of Micronesia',
  'Florida',
  'Georgia',
  'Guam',
  'Hawaii',
  'Idaho',
  'Illinois',
  'Indiana',
  'Iowa',
  'Kansas',
  'Kentucky',
  'Louisiana',
  'Maine',
  'Marshall Islands',
  'Maryland',
  'Massachusetts',
  'Michigan',
  'Minnesota',
  'Mississippi',
  'Missouri',
  'Montana',
  'Nebraska',
  'Nevada',
  'New Hampshire',
  'New Jersey',
  'New Mexico',
  'New York',
  'North Carolina',
  'North Dakota',
  'Northern Mariana Islands',
  'Ohio',
  'Oklahoma',
  'Oregon',
  'Palau',
  'Pennsylvania',
  'Puerto Rico',
  'Rhode Island',
  'South Carolina',
  'South Dakota',
  'Tennessee',
  'Texas',
  'Utah',
  'Vermont',
  'Virgin Islands',
  'Virginia',
  'Washington',
  'West Virginia',
  'Wisconsin',
  'Wyoming',
]

const environmentNameList = [
  'prod',
  'production',
  'dev',
  'dev2',
  'sita',
  'sitb',
  'pidev',
  'staging',
  'reno',
  'virtual - dev',
  'virtual - qa',
  'pistaging',
  'figaro - prod',
  'engprod',
  'engprod2',
  'engnew',
  'figaro - engnew',
  'dev_mock',
  'dev_mg_int',
  'dev_audi_sst',
  'dev_bmw_sit',
  'qa',
  'specbiz_dev',
  'specbiz_prod',
  'spectrum_mobile_dev',
  'spectrum_mobile_prod',
  'mock',
  'ci',
  'stage',
  'local',
  'ephem',
  'figaro - dev',
  'figaro - 2 - production',
  'figaro - complete - mock',
  'figaro - sit - b',
  'ctecpi',
  'N / A',
  'vas',
  'portalslocal',
  'preprod',
  'portalsengprod',
  'portalsdev',
  'portalsqa',
  'portalsuat',
  'portalsfe',
  'qaportal',
  'ephemeral',
  'fe',
  'uat',
  'prod_eft',
]

// When adding new filter, please add new "id" to "filtersList" in client/src/components/shared/chart/utils.ts
export const filters: Record<string, T.Filter> = {
  ENVIRONMENT: {
    id: 'environment',
    label: 'Endpoint',
    type: inputTypes.RADIO_BUTTON,
    initialValue: 'PRODUCTION',
    options: [
      { label: 'Production', value: 'PRODUCTION' },
      { label: 'Staging', value: 'STAGING' },
    ],
  },
  environment: {
    id: 'environment',
    label: 'Endpoint',
    type: inputTypes.RADIO_BUTTON,
    initialValue: 'production',
    options: [
      { label: 'Production', value: 'production' },
      { label: 'Staging', value: 'staging' },
    ],
  },
  DATA_SOURCE: {
    id: 'dataSource',
    label: 'Data Source',
    type: inputTypes.RADIO_BUTTON,
    initialValue: 'QUANTUM',
    options: [
      { label: 'Quantum', value: 'QUANTUM' },
      { label: 'Qube', value: 'QUBE' },
      // { label: 'Airlytics', value: 'AIRLYTICS' },
      { label: 'Internal', value: 'INTERNAL' },
    ],
  },
  APP_VERSION: {
    id: 'appVersion',
    label: 'Application Version',
    type: inputTypes.AUTOCOMPLETE,
    resetOnEntityChange: true,
    useGetOptions: useGetAppVersions,
  },
  APP_SECTION: {
    id: 'appSection',
    label: 'Application Section',
    type: inputTypes.AUTOCOMPLETE,
    resetOnEntityChange: true,
    useGetOptions: useGetAppSections,
  },
  DEVICE_OS: {
    id: 'operatingSystem',
    label: 'Operating System',
    type: inputTypes.AUTOCOMPLETE,
    resetOnEntityChange: true,
    useGetOptions: useGetDeviceOSs,
  },
  VENONA_VERSION: {
    id: 'venonaVersion',
    label: 'Venona Version',
    type: inputTypes.INPUT,
  },
  HELIX_SPEC_VERSION: {
    id: 'helixSpecVersion',
    label: 'Helix Spec Version',
    type: inputTypes.INPUT,
  },
  MSO: {
    id: 'computedMSO',
    label: 'Company (MSO)',
    type: inputTypes.RADIO_BUTTON,
    initialValue: null,
    options: [
      { label: 'All', value: null },
      { label: 'CHARTER', value: 'CHARTER' },
      { label: 'BH', value: 'BH' },
      { label: 'TWC', value: 'TWC' },
      { label: 'Unknown', value: 'Unknown' },
    ],
  },
  TECHNOLOGY_TYPE: {
    type: inputTypes.CHECKBOX,
    id: 'technologyType',
    label: 'Technology Types',
    resetOnEntityChange: true,
    useGetOptions: useGetTechTypeOptions,
  },
  STREAM_TYPE: {
    id: 'streamType',
    label: 'Playback Type',
    options: [
      { label: 'Linear', value: 'linear' },
      { label: 'VOD', value: 'vod' },
      { label: 'DVR', value: 'dvr' },
      { label: 'Other', value: 'other' },
    ],
    type: inputTypes.CHECKBOX,
  },
  CONTENT_CLASS: {
    id: 'contentClass',
    label: 'Content Class',
    options: [
      { label: 'Linear', value: 'linear' },
      { label: 'FVOD', value: 'fod' },
      { label: 'SVOD', value: 'svod' },
      { label: 'TVOD', value: 'tod' },
      { label: 'DVR', value: 'dvr' },
      { label: 'CDVR', value: 'cdvr' },
      { label: 'Trailer', value: 'trailer' },
      { label: 'Extra', value: 'extra' },
    ],
    type: inputTypes.CHECKBOX,
  },
  STREAM_CONTENT_FORMAT: {
    id: 'streamContentFormat',
    label: 'Streaming Format',
    options: [
      { label: 'HTTP Linear Streaming', value: 'hls' },
      { label: 'Smooth Stream', value: 'smooth' },
      { label: 'Dynamic Adaptive Streaming over HTTP', value: 'dash' },
    ],
    type: inputTypes.CHECKBOX,
  },
  NETWORK_STATUS: {
    id: 'networkStatus',
    label: 'Network Status',
    options: [
      { label: 'On Net', value: 'onNet' },
      { label: 'Off Net', value: 'offNet' },
    ],
    type: inputTypes.CHECKBOX,
  },
  CONNECTION_TYPE: {
    id: 'connectionType',
    label: 'Connection Type',
    options: [
      { label: 'WiFi', value: 'wifi' },
      { label: 'Wired', value: 'wired' },
      { label: 'Cell', value: 'cell' },
      { label: 'Offline', value: 'offline' },
    ],
    type: inputTypes.CHECKBOX,
  },
  SPECGUIDE_CONNECTION_TYPE: {
    id: 'connectionType',
    label: 'Connection Type',
    options: [
      { label: 'WiFi', value: 'wifi' },
      { label: 'DOCSIS', value: 'IP' },
      { label: 'QAM', value: 'QAM' },
      { label: 'Unknown', value: 'unknown' },
    ],
    type: inputTypes.CHECKBOX,
  },
  STREAM_CDN: {
    id: 'streamCdn',
    label: 'CDN',
    options: [
      { label: 'TWC', value: 'L-TWC' },
      { label: 'Charter', value: 'L-CHTR' },
    ],
    type: inputTypes.CHECKBOX,
  },
  LEGACY_COMPANY: {
    id: 'computedMSO',
    label: 'Company',
    options: [
      { label: 'CHARTER', value: 'CHARTER' },
      { label: 'BH', value: 'BH' },
      { label: 'TWC', value: 'TWC' },
      { label: 'Unknown', value: 'Unknown' },
    ],
    type: inputTypes.CHECKBOX,
  },
  LEGACY_COMPANY_SPECGUIDE: {
    id: 'computedMSO',
    label: 'Company',
    options: [
      { label: 'CHARTER', value: 'CHARTER' },
      { label: 'BH', value: 'BH' },
      { label: 'TWC', value: 'TWC' },
      { label: 'Unknown', value: 'Unknown' },
    ],
    type: inputTypes.CHECKBOX,
  },
  LOGIN_TYPE: {
    id: 'operation_type',
    label: 'Login Type',
    options: [
      { label: 'Manual Auth', value: 'manualAuth' },
      { label: 'Resume Auth', value: 'resumeAuth' },
      { label: 'Verifier Auth', value: 'verifierAuth' },
      { label: 'Auto Access', value: 'autoAccess' },
      { label: 'Exchange Auth', value: 'tokenExchange' },
    ],
    type: inputTypes.CHECKBOX,
  },
  PLATFORM_TYPE: {
    id: 'platformType',
    label: 'Platform Types',
    options: [
      { label: 'Web', value: 'web' },
      { label: 'Mobile', value: 'mobile' },
    ],
    type: inputTypes.CHECKBOX,
  },
  BROWSER_SPECNET: {
    id: 'browser',
    label: 'Browser',
    realtimeDashboardID: 'browser_specnet',
    type: inputTypes.AUTOCOMPLETE,
    useGetOptions: useGetFilter,
  },
  BROWSER_VIDEO: {
    id: 'browser',
    label: 'Browser',
    realtimeDashboardID: 'browser_video',
    type: inputTypes.AUTOCOMPLETE,
    useGetOptions: useGetFilter,
  },
  BROWSER_PORTALS: {
    id: 'browser',
    label: 'Browser',
    realtimeDashboardID: 'browser_portals',
    type: inputTypes.AUTOCOMPLETE,
    useGetOptions: useGetFilter,
  },
  BROWSER_INTERNAL_QUANTUM: {
    id: 'browser',
    label: 'Browser',
    realtimeDashboardID: 'browser_internal_quantum',
    type: inputTypes.AUTOCOMPLETE,
    useGetOptions: useGetFilter,
  },
  BROWSER_SMB: {
    id: 'browser',
    label: 'Browser',
    type: inputTypes.AUTOCOMPLETE,
    useGetOptions: useGetFilter,
    realtimeDashboardID: 'browser_smb',
  },
  BROWSER_ID_MANAGEMENT: {
    id: 'browser',
    label: 'Browser',
    type: inputTypes.AUTOCOMPLETE,
    useGetOptions: useGetFilter,
    realtimeDashboardID: 'browser_idmanagement',
  },
  DIVISION: {
    id: 'division',
    label: 'Controller',
    type: inputTypes.AUTOCOMPLETE,
    useGetOptions: useGetFilter,
    realtimeDashboardID: 'division',
  },
  API_CLUSTER_VERSION: {
    id: 'apiClusterVersion',
    label: 'API Cluster Version',
    options: [
      { label: 'blue', value: 'blue' },
      { label: 'green', value: 'green' },
      { label: 'NONE', value: 'NONE' },
      { label: 'null', value: 'null' },
    ],
    type: inputTypes.CHECKBOX,
  },
  DRM_TYPE: {
    id: 'drmType',
    label: 'DRM Type',
    options: [
      { label: 'AES', value: 'aes' },
      { label: 'PlayReady', value: 'playReady' },
      { label: 'Adobe PrimeTime', value: 'adobePrimeTime' },
      { label: 'FairPlay', value: 'fairplay' },
    ],
    type: inputTypes.CHECKBOX,
  },
  DAI_ENABLED: {
    id: 'daienabled',
    label: 'DAI',
    options: [
      { label: 'Enabled', value: 'true' },
      { label: 'Disabled', value: 'false' },
    ],
    type: inputTypes.CHECKBOX,
  },
  EXPERIMENT_UUID_STVA: {
    id: 'experimentUuids',
    label: 'Experiment ID',
    type: inputTypes.AUTOCOMPLETE,
    useGetOptions: useGetFilter,
    realtimeDashboardID: 'experiment_uuid_stva',
  },
  EXPERIMENT_UUID_SSPP: {
    id: 'experimentUuids',
    label: 'Experiment ID',
    type: inputTypes.AUTOCOMPLETE,
    useGetOptions: useGetFilter,
    realtimeDashboardID: 'experiment_uuid_sspp',
  },
  ACTIVATED_EXPERIMENTS_STVA: {
    id: 'activatedExperiments',
    label: 'Experiment UUID',
    type: inputTypes.AUTOCOMPLETE,
    useGetOptions: useGetFilter,
    realtimeDashboardID: 'activated_experiments_stva',
  },
  ACTIVATED_EXPERIMENTS_SSPP: {
    id: 'activatedExperiments',
    label: 'Experiment UUID',
    type: inputTypes.AUTOCOMPLETE,
    useGetOptions: useGetFilter,
    realtimeDashboardID: 'activated_experiments_sspp',
  },
  VARIANT_UUID_STVA: {
    id: 'variantUuids',
    label: 'Variant UUID',
    type: inputTypes.AUTOCOMPLETE,
    useGetOptions: useGetFilter,
    realtimeDashboardID: 'variant_uuid_stva',
  },
  VARIANT_UUID_SSPP: {
    id: 'variantUuids',
    label: 'Variant UUID',
    type: inputTypes.AUTOCOMPLETE,
    useGetOptions: useGetFilter,
    realtimeDashboardID: 'variant_uuid_sspp',
  },
  EXPERIMENT_AND_VARIANT_UUIDS: {
    id: 'activatedExperiments',
    label: 'Experiment UUID',
    type: 'EXPERIMENT-AND-VARIANT-UUIDS',
  },
  EXPERIMENT_AND_VARIANT_UUIDS_HIDDEN: {
    id: 'variantUuids',
    label: 'Variant UUID',
    type: 'HIDDEN',
  },
  FORM_FACTOR: {
    id: 'formFactor',
    label: 'Form Factors',
    options: [
      { label: 'PC', value: 'pc' },
      { label: 'Tablet', value: 'tablet' },
      { label: 'Phone', value: 'phone' },
    ],
    type: inputTypes.CHECKBOX,
  },
  LOCATION_STATE: {
    id: 'locationState',
    label: 'State',
    options: stateList,
    type: inputTypes.AUTOCOMPLETE,
  },
  DEVICE_LOCATION: {
    id: 'deviceLocation',
    label: 'Device Location',
    options: [
      { label: 'In Market', value: 'inMarket' },
      { label: 'Out of Market', value: 'outOfMarket' },
      { label: 'In Home', value: 'inHome' },
      { label: 'Unknown', value: 'unknown' },
    ],
    type: inputTypes.CHECKBOX,
  },
  APP_NAME: {
    id: 'applicationName',
    label: 'Application Name',
    type: inputTypes.AUTOCOMPLETE,
    useGetOptions: useGetAppNames,
  },
  APP_TYPE: {
    id: 'applicationType',
    label: 'Application Type',
    type: inputTypes.AUTOCOMPLETE,
    useGetOptions: useGetAppTypes,
  },
  APPLICATION: {
    id: 'applicationNameAndType',
    label: 'Application',
    type: inputTypes.AUTOCOMPLETE,
    useGetOptions: useGetApplications,
  },
  SELF_INSTALL: {
    id: 'selfInstall',
    label: 'Self Install',
    type: inputTypes.CHECKBOX,
    useGetOptions: useGetSelfInstallOptions,
  },
  BILLING_SERVICES: {
    id: 'billingServices',
    label: 'Billing Services',
    options: [
      { label: 'SPS', value: 'sps' },
      { label: 'Legacy', value: 'legacy' },
      { label: 'unknown', value: 'unknown' },
      { label: 'null', value: 'null' },
    ],
    type: inputTypes.CHECKBOX,
  },
  MOBILE_STATUS: {
    id: 'mobileStatus',
    label: 'Service Subscriptions - Mobile',
    type: inputTypes.RADIO_BUTTON,
    initialValue: null,
    options: [
      { label: 'All', value: null },
      { label: 'Active', value: 'active' },
      { label: 'Inactive', value: 'inactive' },
      { label: 'Unknown', value: 'unknown' },
    ],
    filterDescription:
      'Indicates whether the account currently has Spectrum Mobile services active (not a prospect, not disconnected, not in fraud state).',
  },
  CONFIGURATION_FACTORS: {
    id: 'configurationFactors',
    label: 'M2.0 Migration Status',
    options: [
      'Not Planned for Migration (M1)',
      'Pre-Migration (M1)',
      'Brownout (M1)',
      'Blackout (M1)',
      'Migrated (M2)',
      'New Customer (M2)',
      'Unknown',
    ],
    type: inputTypes.AUTOCOMPLETE,
  },
  HIDDEN: {
    id: 'hidden',
    label: 'Hidden',
    type: 'HIDDEN',
  },
  MAPPEDDEVICEPLATFORM: {
    id: 'mapped_device_platform',
    label: 'Mapped Device Platform',
    type: inputTypes.INPUT,
  },
  RESOURCEID: {
    id: 'resourceID',
    label: 'Resource ID',
    type: inputTypes.INPUT,
  },
  ENVIRONMENT_NAME: {
    id: 'environmentName',
    label: 'Environment',
    options: environmentNameList,
    type: inputTypes.AUTOCOMPLETE,
  },
  PRIORITY: {
    id: 'priority',
    label: 'Priority',
    type: inputTypes.RADIO_BUTTON,
    options: [
      { label: 'All', value: null },
      { label: PRIORITY_LEVEL.CRITICAL, value: PRIORITY_LEVEL.CRITICAL },
      { label: PRIORITY_LEVEL.HIGH, value: PRIORITY_LEVEL.HIGH },
      { label: PRIORITY_LEVEL.MEDIUM, value: PRIORITY_LEVEL.MEDIUM },
      { label: PRIORITY_LEVEL.LOW, value: PRIORITY_LEVEL.LOW },
    ],
    initialValue: null,
  },
}

function getTechTypes(currentApps: Array<string>) {
  const options: Array<Record<string, string>> = []

  function addOption(value: string) {
    if (!options.some(option => option?.label === value))
      options.push({ label: value, value: value })
  }

  for (const app of currentApps) {
    if (['OneApp@Roku', 'BulkMDU@Roku'].includes(app)) {
      addOption('Legacy')
      addOption('RSG')
    } else if (['MySpectrum@iOS', 'MySpectrum@Android', 'SpecNet@Web', 'SMB@Web'].includes(app)) {
      addOption('Browser')
      addOption('ConvergedTech')
      addOption('Xamarin')
      addOption('webView')
      addOption('null')
    } else if (['OneApp@SamsungTV', 'BulkMDU@SamsungTV'].includes(app)) {
      addOption('Legacy')
      addOption('TVSDK')
    }
  }
  return options
}

export function useGetTechTypeOptions() {
  const { currentApps } = useApps()
  const options = getTechTypes(currentApps || [])

  return { isLoading: false, options, error: null }
}

function useGetSelfInstallOptions() {
  const { entityID, currentApps } = useApps()
  const mySpectrumApp = currentApps?.find((app: string) => app.includes('MySpectrum'))
  const options =
    entityID?.includes('MySpectrum') || mySpectrumApp
      ? [{ label: 'MSA Self Install', value: 'equipmentSelfInstall' }]
      : []

  return { isLoading: false, options, error: null }
}

export function useGetAppNames() {
  const { currentApps } = useApps()
  const options = getAppNames(currentApps)

  return { isLoading: false, options, error: null }
}

export function useGetApplications() {
  const { currentGroup } = useGroups()
  const options = currentGroup?.applicationIDs
  if (!options) {
    return { isLoading: true, options: [], error: null }
  }
  return { isLoading: false, options, error: null }
}

export function useGetAppTypes() {
  const { currentApps } = useApps()
  const options = getAppTypes(currentApps)

  return { isLoading: false, options, error: null }
}

export function useGetAppVersions(appObj?: Record<string, any>) {
  const [options, setOptions] = React.useState<OptionProps[]>([])
  const location = useLocation()
  const { environment, period } = queryString.parse(location.search)
  const { currentGroup } = useGroups()
  const { currentApps, entityID, currentDataSources } = useApps()
  const appFilter = currentGroup ? { appIDs: currentApps } : { appID: entityID }
  const defaultTimeFilterRange = getDefaultTimeFilterRange()

  const { isLoading, error, data } = useApi({
    service: services.realtimeDashboardApi,
    query: queryString.stringify({
      ...appFilter,
      metric: currentDataSources?.includes('qube') ? 'api_calls_full.top' : 'devices_all_types.top',
      period: period || defaultTimeFilterRange,
      partialData: true,
      environment: environment || 'production',
      limit: 20,
      step: 86400000,
      group: currentDataSources?.includes('qube') ? ['applicationAppVersion'] : '',
    }),
  })

  React.useEffect(() => {
    const newOptions = data?.rows?.map((option: { 'Application Version': string }) => {
      const appVersion = option['Application Version'] || option['applicationAppVersion']
      const count = option['Count'] || option['count']
      const metric = currentDataSources?.includes('qube') ? 'api calls' : 'devices'

      return {
        value: appVersion,
        label: `${appVersion} - ${numeral(count).format('0a')} ${metric}`,
      }
    })
    if (data) {
      if (!isEqual(newOptions, options) && options[0] !== 'No App Versions Found') {
        setOptions(isEmpty(newOptions) || isNil(newOptions) ? ['No App Versions Found'] : newOptions);
      }
    }
  }, [isLoading, error, data, options, currentDataSources])

  return { isLoading, error, options }
}

export function useGetDeviceOSs() {
  const [options, setOptions] = React.useState<OptionProps[]>([])
  const location = useLocation()
  const { environment, period } = queryString.parse(location.search)
  const { currentGroup } = useGroups()
  const { currentApps, entityID, currentDataSources } = useApps()
  const appFilter = currentGroup ? { appIDs: currentApps } : { appID: entityID }
  const defaultTimeFilterRange = getDefaultTimeFilterRange()

  const { isLoading, error, data } = useApi({
    service: services.druidFront,
    query: queryString.stringify({
      ...appFilter,
      metric: 'event_count',
      period: period || defaultTimeFilterRange,
      environment: environment || 'production',
      source: 'dasp',
      limit: 20,
      step: 0,
      group: 'operatingSystem',
    }),
  })

  React.useEffect(() => {
    const newOptions = data?.result?.map((option: { dimension1: string }) => {
      const operatingSystem = option.dimension1

      return {
        value: operatingSystem,
        label: `${operatingSystem}`,
      }
    })

    if (data) {
      if (!isEqual(newOptions, options)) {
        setOptions(newOptions)
      }
    }
  }, [isLoading, error, data, options, currentDataSources])

  return { isLoading, error, options }
}

export function useGetAppSections(appObj?: Record<string, any>) {
  const [options, setOptions] = React.useState<OptionProps[]>([])
  const location = useLocation()
  const { environment, period } = queryString.parse(location.search)
  const { currentGroup } = useGroups()
  const { currentApps, entityID } = useApps()
  const appFilter = currentGroup ? { appIDs: currentApps } : { appID: entityID }
  const defaultTimeFilterRange = getDefaultTimeFilterRange()
  const { isLoading, error, data } = useApi({
    service: services.realtimeDashboardApi,
    query: queryString.stringify({
      ...appFilter,
      metric: 'top_sections.top',
      period: period || defaultTimeFilterRange,
      partialData: true,
      environment: environment || 'production',
      limit: 100,
      step: 86400000,
      group: '',
    }),
  })

  React.useEffect(() => {
    // when provided with a string, map will pluck that property out of a collection.
    // Since this includes nulls, we also need to filter on truthyness.
    const newOptions = filter(map(data?.rows, 'App Section'))
    if (!isEqual(newOptions, options)) {
      setOptions(newOptions)
    }
  }, [isLoading, error, data, options])

  return { isLoading, error, options }
}

function useGetFilter({ realtimeDashboardID }: { realtimeDashboardID?: Record<string, any> }) {
  const [options, setOptions] = React.useState<OptionProps[]>([])
  const { isLoading, error, data } = useApi({
    service: services.prismUIFilters,
    query: queryString.stringify({
      filterID: realtimeDashboardID,
    }),
  })

  React.useEffect(() => {
    if (data) {
      setOptions(
        map(data, (value, key) => {
          return key
        })
      )
    }
  }, [isLoading, error, data])

  return { isLoading, error, options }
}

function getAppNames(appIDs: string[] = []): string[] {
  const appNames = appIDs.map((app: string) => app.split('@')[0])
  return uniq(appNames) as string[]
}

function getAppTypes(appIDs: string[] = []): string[] {
  const appNames = appIDs.map((app: string) => app.split('@')[1])
  return uniq(appNames) as string[]
}

const appFilterOptions = {
  MySpectrum: (screen: string) => [
    filters.environment,
    filters.LEGACY_COMPANY,
    filters.APP_VERSION,
    filters.ENVIRONMENT_NAME,
    filters.SELF_INSTALL,
    filters.MOBILE_STATUS,
    filters.CONFIGURATION_FACTORS,
    filters.EXPERIMENT_AND_VARIANT_UUIDS,
    filters.EXPERIMENT_AND_VARIANT_UUIDS_HIDDEN,
    filters.PLATFORM_TYPE,
    filters.FORM_FACTOR,
  ],
  IDManagement: (screen: string) => [
    filters.environment,
    filters.FORM_FACTOR,
    filters.BROWSER_ID_MANAGEMENT,
    filters.EXPERIMENT_AND_VARIANT_UUIDS,
    filters.EXPERIMENT_AND_VARIANT_UUIDS_HIDDEN,
    filters.APP_VERSION,
    filters.ENVIRONMENT_NAME,
  ],
  SMB: (screen: string) => [
    filters.environment,
    filters.PLATFORM_TYPE,
    filters.LEGACY_COMPANY,
    filters.BROWSER_SMB,
    filters.APP_VERSION,
    filters.ENVIRONMENT_NAME,
    filters.EXPERIMENT_AND_VARIANT_UUIDS,
    filters.EXPERIMENT_AND_VARIANT_UUIDS_HIDDEN,
    filters.MOBILE_STATUS,
    filters.CONFIGURATION_FACTORS,
    filters.FORM_FACTOR,
    filters.DEVICE_OS,
  ],
  'Spectrum Guide': (screen: string) => [
    filters.environment,
    filters.LEGACY_COMPANY_SPECGUIDE,
    filters.SPECGUIDE_CONNECTION_TYPE,
    filters.DIVISION,
    filters.APP_VERSION,
    filters.EXPERIMENT_AND_VARIANT_UUIDS,
    filters.EXPERIMENT_AND_VARIANT_UUIDS_HIDDEN,
  ],
  SpecNet: (screen: string) => [
    filters.environment,
    filters.PLATFORM_TYPE,
    filters.LEGACY_COMPANY,
    filters.BROWSER_SPECNET,
    filters.APP_VERSION,
    filters.ENVIRONMENT_NAME,
    filters.EXPERIMENT_AND_VARIANT_UUIDS,
    filters.EXPERIMENT_AND_VARIANT_UUIDS_HIDDEN,
    filters.MOBILE_STATUS,
    filters.CONFIGURATION_FACTORS,
    filters.FORM_FACTOR,
    filters.DEVICE_OS,
  ],
}

const groupFilterOptions = {
  video: [
    filters.NETWORK_STATUS,
    filters.CONNECTION_TYPE,
    filters.DEVICE_LOCATION,
    filters.STREAM_TYPE,
    filters.CONTENT_CLASS,
    filters.STREAM_CONTENT_FORMAT,
    filters.STREAM_CDN,
    filters.DRM_TYPE,
    filters.DAI_ENABLED,
    filters.LOCATION_STATE,
    filters.BROWSER_VIDEO,
    filters.EXPERIMENT_AND_VARIANT_UUIDS,
    filters.EXPERIMENT_AND_VARIANT_UUIDS_HIDDEN,
  ],
  portals: [
    filters.PLATFORM_TYPE,
    filters.FORM_FACTOR,
    filters.SELF_INSTALL,
    filters.BROWSER_PORTALS,
    filters.ENVIRONMENT_NAME,
    filters.EXPERIMENT_AND_VARIANT_UUIDS,
    filters.EXPERIMENT_AND_VARIANT_UUIDS_HIDDEN,
    filters.MOBILE_STATUS,
    filters.CONFIGURATION_FACTORS,
    filters.DEVICE_OS,
  ],
  internal_apps: [
    filters.environment,
    filters.APP_VERSION,
    filters.BROWSER_INTERNAL_QUANTUM,
    filters.APPLICATION,
  ],
}
const defaultVideoAndPortalsFilterOptions = [
  filters.environment,
  filters.APP_VERSION,
  filters.APPLICATION,
  filters.LEGACY_COMPANY,
]

function getFilterOptionsForOneApp(screen?: string): T.Filter[] {
  switch (screen) {
    case 'authentication':
      return [
        filters.environment,
        filters.LEGACY_COMPANY,
        filters.NETWORK_STATUS,
        filters.CONNECTION_TYPE,
        filters.LOGIN_TYPE,
        filters.APP_VERSION,
        filters.EXPERIMENT_AND_VARIANT_UUIDS,
        filters.EXPERIMENT_AND_VARIANT_UUIDS_HIDDEN,
        filters.LOCATION_STATE,
      ]
    case 'apis':
    case 'apisDetails':
      return [
        filters.environment,
        filters.LEGACY_COMPANY,
        filters.NETWORK_STATUS,
        filters.CONNECTION_TYPE,
        filters.APP_VERSION,
        filters.EXPERIMENT_AND_VARIANT_UUIDS,
        filters.EXPERIMENT_AND_VARIANT_UUIDS_HIDDEN,
        filters.DEVICE_LOCATION,
        filters.LOCATION_STATE,
      ]
    case 'overview':
      return [
        filters.environment,
        filters.LEGACY_COMPANY,
        filters.NETWORK_STATUS,
        filters.CONNECTION_TYPE,
        filters.STREAM_CDN,
        filters.STREAM_TYPE,
        filters.CONTENT_CLASS,
        filters.STREAM_CONTENT_FORMAT,
        filters.DRM_TYPE,
        filters.EXPERIMENT_AND_VARIANT_UUIDS,
        filters.EXPERIMENT_AND_VARIANT_UUIDS_HIDDEN,
        filters.DAI_ENABLED,
        filters.APP_VERSION,
        filters.LOCATION_STATE,
      ]
    case 'pageViews':
      return [
        filters.environment,
        filters.LEGACY_COMPANY,
        filters.CONNECTION_TYPE,
        filters.DRM_TYPE,
        filters.DAI_ENABLED,
        filters.APP_VERSION,
        filters.EXPERIMENT_AND_VARIANT_UUIDS,
        filters.EXPERIMENT_AND_VARIANT_UUIDS_HIDDEN,
        filters.DEVICE_LOCATION,
        filters.LOCATION_STATE,
      ]
    default:
      return [
        filters.environment,
        filters.LEGACY_COMPANY,
        filters.NETWORK_STATUS,
        filters.CONNECTION_TYPE,
        filters.STREAM_CDN,
        filters.DEVICE_LOCATION,
        filters.STREAM_TYPE,
        filters.CONTENT_CLASS,
        filters.STREAM_CONTENT_FORMAT,
        filters.DRM_TYPE,
        filters.DAI_ENABLED,
        filters.APP_VERSION,
        filters.EXPERIMENT_AND_VARIANT_UUIDS,
        filters.EXPERIMENT_AND_VARIANT_UUIDS_HIDDEN,
        filters.LOCATION_STATE,
      ]
  }
}

function getDefaultFilters(domain?: string): T.Filter[] {
  if (domain === 'video') {
    return [
      filters.environment,
      filters.APP_VERSION,
      filters.EXPERIMENT_AND_VARIANT_UUIDS,
      filters.EXPERIMENT_AND_VARIANT_UUIDS_HIDDEN,
    ]
  }
  if (domain === 'portals') {
    return [
      filters.environment,
      filters.APP_VERSION,
      filters.ENVIRONMENT_NAME,
      filters.EXPERIMENT_AND_VARIANT_UUIDS,
      filters.EXPERIMENT_AND_VARIANT_UUIDS_HIDDEN,
    ]
  }
  return [filters.environment, filters.APP_VERSION]
}

function getTVEFilters(): T.Filter[] {
  return [filters.environment, filters.MAPPEDDEVICEPLATFORM, filters.RESOURCEID]
}

function getFilterOptionsForGroup(currentDomains?: string[]): T.Filter[] {
  const defaultGroupFilters =
    (currentDomains && currentDomains[0]) === 'internal_apps'
      ? []
      : defaultVideoAndPortalsFilterOptions

  return currentDomains?.reduce(
    (acc: any, domain: string) => acc.concat(groupFilterOptions[domain]),
    defaultGroupFilters
  )
}

export function getAvailableFilters(
  appIDs?: string[],
  currentDomains?: string[],
  currentGroup?: any,
  screen?: string
): T.Filter[] {
  const appNames = getAppNames(appIDs)
  const appName = appNames[0]
  const isGroup = !!currentGroup?.applicationIDs
  const currentDomain = currentDomains?.[0]

  if (isGroup) {
    return getFilterOptionsForGroup(currentDomains)
  }

  if (appName === 'OneApp') {
    // OneApp has special logic
    return getFilterOptionsForOneApp(screen)
  }

  if (appName === 'TVE') {
    return getTVEFilters()
  }

  if (appFilterOptions.hasOwnProperty(appName)) {
    // App filters can be contextual based on screen.
    // AppFilterOptions[appName] will returns a T.Filter containing null values which must before returning them:
    return appFilterOptions[appName](screen).filter(
      (filter: T.Filter | null): filter is T.Filter => filter !== null
    )
  }

  return getDefaultFilters(currentDomain)
}

export function useGetMobileStatusFilters({
  includeHiddenFilter,
}: {
  includeHiddenFilter?: boolean
}) {
  const { currentApps } = useApps()
  // https://gitlab.spectrumflow.net/client-analytics/prism/-/issues/1673#note_1767570
  const appNames = ['MySpectrum', 'SMB', 'SpecNet']
  const showFilters = currentApps?.some(app => {
    const appName = app?.split('@')[0]
    return appNames.includes(appName)
  })
  if (showFilters) {
    return { filters: [] }
  }
  return { filters: includeHiddenFilter ? [filters.HIDDEN] : [] }
}
