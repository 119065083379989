import React from 'react'
import map from 'lodash/map'
import truncate from 'lodash/truncate'
import { navigate, useLocation } from '@reach/router'
import Tooltip from '@material-ui/core/Tooltip'
import queryString from 'query-string'
import { chartColors } from 'common/constants'
import Icon from '../icon/Icon'
import * as S from './styled'

const MAX_LABEL_LENGTH = 14

function CustomLegend({
  data,
  customLegendClickPathObj,
  step,
  colors = chartColors,
  withoutAbsolutePosition,
  setLegendHoverColor,
  resetLegendHoverColor,
}: CustomLegendProps) {
  const location = useLocation()
  const labels = data
  const colorsWithLabels = map(labels, (label, index: number) => {
    return { color: colors[index % colors.length], label }
  })

  function handleLegendClick(clickedItemName: string) {
    const extraQueryParamFromUrl = customLegendClickPathObj?.queryParamFromUrl || ''
    const query = queryString.parse((location && location.search) || '')
    const extraParams = customLegendClickPathObj?.extraParams || {}

    const newQuery = queryString.stringify({
      ...query,
      [customLegendClickPathObj?.queryParam as any]: clickedItemName,
      [extraQueryParamFromUrl]: query[extraQueryParamFromUrl as any],
      ...extraParams,
    })
    if (customLegendClickPathObj) {
      navigate(`${customLegendClickPathObj.pathName}?${newQuery}`)
    }
  }

  const mappedLegends = colorsWithLabels.map((individualLabel: any, index: number) => {
    let finalLabel = ''
    if (individualLabel.label.length > MAX_LABEL_LENGTH) {
      finalLabel = truncate(individualLabel.label, { length: MAX_LABEL_LENGTH })
    } else {
      finalLabel = individualLabel.label
    }

    function handleActiveLegendItem(legendColor: string) {
      setLegendHoverColor && setLegendHoverColor(legendColor)
    }

    return (
      <div
        key={index}
        onMouseEnter={() => handleActiveLegendItem(individualLabel.color)}
        onMouseLeave={resetLegendHoverColor && (() => resetLegendHoverColor())}
      >
        <Tooltip title={individualLabel.label} key={`${individualLabel.label}_${index}`}>
          <S.LabelContainer
            key={`${individualLabel.color}`}
            data-pho={`label-${individualLabel.label}`}
            onClick={() => customLegendClickPathObj && handleLegendClick(individualLabel.label)}
            clickable={customLegendClickPathObj}
            color={individualLabel.color}
          >
            <S.Circle color={individualLabel.color} />
            <span>{finalLabel}</span>
            {customLegendClickPathObj ? <Icon name="ChevronRight" size="xxs" /> : ''}
          </S.LabelContainer>
        </Tooltip>
      </div>
    )
  })

  return (
    <S.LegendDisplay>
      <S.LegendContainer withoutAbsolutePosition={withoutAbsolutePosition}>
        {mappedLegends}
      </S.LegendContainer>
    </S.LegendDisplay>
  )
}

interface CustomLegendProps {
  data: any
  filter?: { [key: string]: any }
  period?: string
  step?: string
  colors?: any[]
  customLegendClickPathObj: {
    pathName: string
    queryParam: string
    queryParamFromUrl?: string
    extraParams?: { [key: string]: String }
  }
  withoutAbsolutePosition?: boolean
  setLegendHoverColor?: Function
  resetLegendHoverColor?: Function
}

export default CustomLegend
