import React from 'react'
import axios from 'axios'
import { rootApiUrl } from 'common/constants'
import services from 'common/services'

export interface UserPageFavorite {
  _id?: string
  title: string
  url: string
  description?: string
  pageName?: string
  appOrGroupName?: string
  appliedFilterQueryString?: string
}

interface User {
  id: string
  pid?: string | null
  firstName?: string | null
  lastName?: string | null
  roles?: string[] | null
  favorites?: string[] | null
  eventCaseFavorites?: string[] | null
  avatar?: string | null
  preferredInitials?: string | null
  avatarBackgroundColor?: string | null
  pageFavorites?: UserPageFavorite[] | null
  groupFavorites?: string[] | null
  lastLoginDate?:string | null
}

export interface PrismUserState extends User {
  update: (updates: Omit<User, 'id'>) => Promise<User | null>
}

async function getPrismUser(
  prismUserState: PrismUserState,
  setPrismUserState: React.Dispatch<React.SetStateAction<PrismUserState>>
) {
  const accessToken = localStorage.getItem('accessToken') as string
  try {
    const { data: user } = await axios.get(
      `${rootApiUrl}${services.users.url}/${prismUserState.id}`,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    )
    if (!user) {
      throw new Error('User failed to retrieve')
    }
    setPrismUserState(user)
  } catch (error: any) {
    if (error?.message.includes('404')) {
      createPrismUser(prismUserState, setPrismUserState)
    } else {
      console.error(error)
      setPrismUserState({
        ...prismUserState,
        pid: null,
        firstName: null,
        lastName: null,
        roles: null,
        favorites: null,
        eventCaseFavorites: null,
        avatar: null,
        preferredInitials: null,
        avatarBackgroundColor: null,
        pageFavorites: null,
        groupFavorites: null,
        lastLoginDate: null,
      })
    }
  }
}

async function createPrismUser(
  prismUserState: PrismUserState,
  setPrismUserState: React.Dispatch<React.SetStateAction<PrismUserState>>
) {
  const accessToken = localStorage.getItem('accessToken') as string
  try {
    const { data: newUser } = await axios.post(
      `${rootApiUrl}${services.users.url}/new`,
      { id: prismUserState.id },
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    )
    if (!newUser) {
      throw new Error('User creation failed')
    }
    setPrismUserState(newUser)
  } catch (error) {
    console.error(error)
    setPrismUserState({
      ...prismUserState,
      pid: null,
      firstName: null,
      lastName: null,
      roles: null,
      favorites: null,
      eventCaseFavorites: null,
      avatar: null,
      preferredInitials: null,
      avatarBackgroundColor: null,
      pageFavorites: null,
      groupFavorites: null,
      lastLoginDate: null,
    })
  }
}

async function updatePrismUser(
  prismUserState: PrismUserState,
  setPrismUserState: React.Dispatch<React.SetStateAction<PrismUserState>>,
  updates: Omit<User, 'id'>
): Promise<User | null> {
  const id = localStorage.getItem('id') as string
  const accessToken = localStorage.getItem('accessToken') as string
  try {
    const { data: updatedUser } = await axios.patch(
      `${rootApiUrl}${services.users.url}/${id}`,
      updates,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    )
    if (!updatedUser) {
      throw new Error('User update failed')
    }
    setPrismUserState({ ...prismUserState, ...updatedUser })
    return updatedUser
  } catch (error: any) {
    if (error.message.includes('User not found')) {
      createPrismUser(prismUserState, setPrismUserState)
    } else {
      console.error(error)
    }
    return null
  }
}

const initialState = {
  id: 'initializing...',
  update: async () => null,
}

export const PrismUserContext = React.createContext<PrismUserState>(initialState)

function PrismUser({ children }: { children: React.ReactNode }) {
  const id = localStorage.getItem('id') as string
  const [prismUserState, setPrismUserState] = React.useState<PrismUserState>({
    ...initialState,
    id,
  })

  function statefulUpdatePrismUser(updates: Omit<User, 'id'>) {
    return updatePrismUser(prismUserState, setPrismUserState, updates)
  }

  React.useEffect(() => {
    getPrismUser(prismUserState, setPrismUserState)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <PrismUserContext.Provider value={{ ...prismUserState, update: statefulUpdatePrismUser }}>
      {children}
    </PrismUserContext.Provider>
  )
}

export default PrismUser
