import { useLocation } from '@reach/router'
import Flex from 'components/shared/flex/Flex'
import { Card } from 'components/shared/Card'
import logo from 'assets/logo.svg'
import FeedbackButton from 'components/shared/feedbackButton'
import PageFavorites from 'components/shared/PageFavorites'
import useAuth from 'hooks/useAuth'
import useRoles, { ROLES } from 'hooks/useRoles'
import ApplicationSwitcher from './ApplicationSwitcher'
import ToolsSwitcher from './ToolsSwitcher'
import GroupSwitcher from './GroupSwitcher'
import DocsSwitcher from './DocsSwitcher'
import DataSourcesSwitcher from './DataSourcesSwitcher'
import SettingsButton from './SettingsButton'
import KryptoButton from './KryptoButton'
import UserMenu from './UserMenu'
import useApps from 'hooks/useApps'
import { getPrismLogoLink } from './utils'
import * as S from './styles'
import { element } from 'components/shared/utils/spacing'
import DevBanner from '../DevBanner'
import { useState } from 'react'
import PrismAdminSwitcher from './PrismAdmin'

function Topbar() {
  const [showBanner, setShowBanner] = useState<boolean>(
    process.env.REACT_APP_ENV === 'rvw' || process.env.REACT_APP_ENV === 'dev'
  )
  const auth = useAuth()
  const { authorizeRoles } = useRoles()
  const isPrismAdmin = auth.user?.roles.includes('prism-admin')
  const { appsByDataSource, entityID, currentApps } = useApps()
  const { search } = useLocation()
  const path = useLocation().pathname
  const isPageFavoritesAuthorized = authorizeRoles({
    rolesToMatch: [ROLES.PRISM_BASIC],
    contains: true,
  })

  if (['/welcome', '/survey'].includes(path)) return <></>

  return (
    <>
      {showBanner && <DevBanner setShowBanner={setShowBanner} />}
      <Card
        maxHeight="3rem"
        minHeight="3rem"
        data-pho="topbar"
        elevation="3"
        disableShadow
        disablePadding
      >
        <Flex alignItems="center" padding="0 1rem">
          <S.LogoLink
            to={getPrismLogoLink(appsByDataSource, currentApps, entityID, search)}
            data-pho="topbarLogoLink"
          >
            <S.PrismLogo src={logo} alt="logo" data-pho="topbarLogo" />
            <S.PrismLogoName data-pho="topbarTitle">Prism</S.PrismLogoName>
          </S.LogoLink>

          <Flex flex="1" margin="0 0 0 1rem" justifyContent="space-between" alignItems="center">
            <Flex center gap={element.xxl}>
              <ApplicationSwitcher />
              <GroupSwitcher />
              <DataSourcesSwitcher />
              <ToolsSwitcher />
              <DocsSwitcher />
              {isPrismAdmin && <PrismAdminSwitcher />}
            </Flex>
            <Flex center>
              {isPrismAdmin && <SettingsButton />}
              <KryptoButton />
              <FeedbackButton />
              {isPageFavoritesAuthorized && <PageFavorites />}
              <UserMenu />
            </Flex>
          </Flex>
        </Flex>
      </Card>
    </>
  )
}

export default Topbar
