import React, { lazy, Suspense } from 'react'
import { Router, Redirect, RouteComponentProps, useLocation, navigate } from '@reach/router'
import Flex from 'components/shared/flex/Flex'
import useAuth from 'hooks/useAuth'
import useApps from 'hooks/useApps'
import { LoadingSpinner } from 'components/shared/LoadingSpinner'
import NotFoundScreen from 'screens/404'

const AsyncToolsScreens = lazy(() => import('./tools'))
const AsyncDatalensScreens = lazy(() => import('./datalens'))
const AsyncRootRedirect = lazy(() => import('./RootRedirect'))
const AsyncWelcomeScreen = lazy(() => import('./welcome'))
const AsyncEntityID = lazy(() => import('./entityID'))
const AsyncDocsScreens = lazy(() => import('./docs'))
const AsyncDataSourcesScreens = lazy(() => import('./data-sources'))
const AsyncReleaseScreens = lazy(() => import('./release'))
const AsyncNotFoundScreen = NotFoundScreen
const AsyncRequestAccessScreen = lazy(() => import('./RequestAccess'))
const AsyncPrismAdminScreens = lazy(() => import('./PrismAdmin'))

export default function Screens() {
  const { pathname, search } = useLocation()
  const { apps, currentApps } = useApps()
  const { isAuthorized, isDocsAuthorized } = useAuth()

  // Navigate to first favorite
  React.useEffect(() => {
    if (currentApps?.length === 0) {
      const currentRootPath = decodeURIComponent(pathname).split('/')[1]

      const correctAppName = apps.filter(app => {
        return app.toLowerCase() === currentRootPath.toLowerCase()
      })
      if (correctAppName[0]) {
        const newPath = pathname.replace(currentRootPath, correctAppName[0])
        navigate(`${newPath}${search}`)
      }
    }
  }, [currentApps?.length, apps, pathname, search])

  if (isAuthorized) {
    return (
      <Flex flex="1" minHeight="0">
        <Suspense fallback={<LoadingSpinner />}>
          <Router component={React.Fragment} primary={false}>
            <AsyncRootRedirect path="/" />
            <AsyncWelcomeScreen path="/welcome" />
            <AsyncRequestAccessScreen path="request-access" />
            <AsyncEntityID path=":entityID/*" />
            <AsyncToolsScreens path="tools/*" />
            <AsyncDatalensScreens path="datalens/:platformName/*" />
            <AsyncDocsScreens path="documentation/*" />
            <AsyncDataSourcesScreens path="data-sources/*" />
            <AsyncReleaseScreens path="release/*" />
            <AsyncPrismAdminScreens path="prismadmin/*" />
            <AsyncNotFoundScreen default />
            {/* REDIRECT FOR APP/GROUP WITH NO PATH */}
            <Redirect from=":entityID" to="/:entityID/client-analytics/overview" noThrow />
          </Router>
        </Suspense>
      </Flex>
    )
  }
  if (isDocsAuthorized) {
    return (
      <Flex flex="1" minHeight="0">
        <Suspense fallback={<LoadingSpinner />}>
          <Router component={React.Fragment} primary={false}>
            <AsyncRootRedirect path="/" />
            <AsyncDocsScreens path="documentation/*" />
            <AsyncRequestAccessScreen path="request-access" />
            <AsyncWelcomeScreen path="welcome" />
            <Redirect from="/" to="/welcome" noThrow />
            <AsyncNotFoundScreen default />
          </Router>
        </Suspense>
      </Flex>
    )
  }
  return (
    <Flex flex="1" minHeight="0">
      <Suspense fallback={<LoadingSpinner />}>
        <Router component={React.Fragment} primary={false}>
          <AsyncRequestAccessScreen path="request-access" />
          <AsyncWelcomeScreen path="welcome" />
          <Redirect from="/" to="/welcome" noThrow />
          <Redirect from="/*" to="welcome?redirected=true" noThrow />
        </Router>
      </Suspense>
    </Flex>
  )
}

export interface ScreenProps extends RouteComponentProps {
  '*'?: string
  pageName?: string
  title?: string
}
