import React from 'react'
import isNil from 'lodash/isNil'
import { Button } from 'components/shared/Button'
import useFilterMenuOpenCloseState from 'hooks/useFilterMenuOpenCloseState'
import useOnClickOutside from 'hooks/useOnClickOutside'
import PopoverMenu from 'components/shared/popover'
import FilterMenuContent from './Content'
import * as S from './styled'
import * as T from './types'

const MULTIPLE_FILTER_X_COORDINATE = -670
const ONE_FILTER_X_COORDINATE = -450

function FilterMenu({
  filters,
  values,
  updateValues,
  batchUpdateValues,
  isGroupByMenu,
  defaultX,
  defaultY = 0,
  disabled,
  useFilterApplyButton,
}: T.FilterMenuProps) {
  const filterMenuContainerRef = React.useRef<HTMLDivElement>(null)
  const [filterValues, setFilterValues] = React.useState(values || {})

  useOnClickOutside(filterMenuContainerRef, () => {
    const contentContainer = document.getElementById('autocomplete-menu')
    const isAutocompleteFilterClosed = isNil(contentContainer)

    if (isAutocompleteFilterClosed) {
      closeFilterMenu()
    }
  })

  const {
    isFilterMenuOpen,
    toggleFilterMenu,
    closeFilterMenu,
    filterMenuPosition,
    setFilterMenuPosition,
    disableDragging,
  } = useFilterMenuOpenCloseState()

  function getXCoordinate() {
    const filterCount = filters.length
    return filterCount > 1 ? MULTIPLE_FILTER_X_COORDINATE : ONE_FILTER_X_COORDINATE
  }
  const panelXCoordinate = defaultX ? defaultX : getXCoordinate()

  function renderGroupByMenu() {
    return (
      <PopoverMenu
        anchorContent="Group"
        anchorButtonProps={{
          icon: 'Layers',
          variant: 'neutral',
          'data-pho': 'groupButton',
          'aria-haspopup': 'true',
          disabled,
        }}
        anchorButton={Button}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {({ isPopoverOpen, handleClose }) =>
          isPopoverOpen && (
            <FilterMenuContent
              handleClose={handleClose}
              filters={filters}
              values={values}
              updateValues={updateValues}
              batchUpdateValues={batchUpdateValues}
              setFilterValues={setFilterValues}
            />
          )
        }
      </PopoverMenu>
    )
  }

  if (isGroupByMenu) {
    return renderGroupByMenu()
  }

  return (
    <>
      <Button
        aria-haspopup="true"
        onClick={toggleFilterMenu}
        data-pho="filterMenuButton"
        disabled={disabled}
        variant="borderless"
        icon="Filter"
      >
        Filter
      </Button>
      <S.FilterMenuContainer ref={filterMenuContainerRef}>
        <S.RndPanel
        disableDragging={disableDragging}
          default={{
            x: panelXCoordinate,
            y: defaultY,
          }}
          size={{ width: filterMenuPosition?.width, height: filterMenuPosition?.height }}
          onDragStop={(e: any, d: { x: number; y: number }) => {
            if (d.x !== filterMenuPosition?.x || d.y !== filterMenuPosition?.y) {
              setFilterMenuPosition({ ...filterMenuPosition, x: d.x, y: d.y })
            }
          }}
          onResize={(
            e: any,
            direction: any,
            ref: any,
            delta: any,
            position: { x: number; y: number }
          ) => {
            setFilterMenuPosition({
              width: ref.offsetWidth,
              height: ref.offsetHeight,
              ...position,
            })
          }}
          position={filterMenuPosition}
          enableUserSelectHack={false}
          style={!isFilterMenuOpen && { visibility: 'hidden' }}
          data-test="filterMenu"
        >
          <FilterMenuContent
            handleClose={closeFilterMenu}
            filters={filters}
            values={values}
            updateValues={updateValues}
            batchUpdateValues={batchUpdateValues}
            setFilterValues={setFilterValues}
            filterValues={filterValues}
            useFilterApplyButton={useFilterApplyButton}
          />
        </S.RndPanel>
      </S.FilterMenuContainer>
    </>
  )
}

export default FilterMenu
