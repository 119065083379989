import React, { useState } from 'react'
import { Tooltip } from '@material-ui/core'
import { IconButton } from 'components/shared/IconButton'
import { Button } from 'components/shared/Button'
import { LoadingSpinner } from 'components/shared/LoadingSpinner'
import { useMutation } from '@apollo/client'
import { UPDATE_FEEDBACK_ONE } from '../mutations'
import { getVocClient } from '../utils'
import useFilterValues from 'hooks/useFilterValues'
import useVocAuth from 'hooks/useVocAuth'
import ErrorMessage from 'components/shared/ErrorMessage'
import * as S from '../styles'
import { types } from '..'
import Highlighter from 'react-highlight-words'

function NoteTableCell({ row, type, columnWidths, feedbackTableDataRefetch,textToSearch, HighlightElement }: types.NoteTableCellProps) {
  const [isEditing, setIsEditing] = useState(false)
  const rowValue = row[type] ?? '' ;
  const [note, setNote] = useState(rowValue)

  const { entityID } = useVocAuth()
  const { environment } = useFilterValues()

  const client = getVocClient(environment as string, true)
  const [updateFeedback, { loading, error }] = useMutation(UPDATE_FEEDBACK_ONE, {
    client,
  })

  const handleEditClick = () => setIsEditing(true)
  const handleCancel = () => {
    setIsEditing(false)
    setNote(rowValue)
  }

  const handleSave = async () => {
    try {
      if (note !== row[type]) {
        const { data } = await updateFeedback({
          variables: { _id: row._id, entityID, notes: note },
        })
        if (data) {
          feedbackTableDataRefetch()
        }
      }
      setIsEditing(false)
    } catch (err) {
      console.error('Error updating feedback:', err)
    }
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNote(e.target.value)
  }

  if (error) {
    return <ErrorMessage errorMessage="Voice of Customer Edit Notes Category Input Field" />
  }

  return (
    <S.StyledVerbatimTableCell align="left">
      <S.TableCellColumnWidth width={columnWidths[type]}>
        <S.NoteTableContainer>
          <Tooltip title="+ Add a note">
            <span>
              <IconButton onClick={handleEditClick} icon="Edit" />
            </span>
          </Tooltip>
          {isEditing ? (
            <S.NoteTbleAction>
              <S.StyledTextarea
                value={note}
                onChange={handleInputChange}
                autoFocus
                width={columnWidths[type] - 70}
              />
              {loading ? (
                <LoadingSpinner />
              ) : (
                <S.NoteActionButton>
                  <Button iconSize="sm" variant="borderless" onClick={handleSave}>
                    Save Note
                  </Button>
                  <Button variant="borderless" onClick={handleCancel}>
                    Cancel
                  </Button>
                </S.NoteActionButton>
              )}
            </S.NoteTbleAction>
          ) : (
            <>
              <Highlighter
                highlightTag={HighlightElement}
                searchWords={[textToSearch ?? '']}
                autoEscape={true}
                textToHighlight={note ? note : ''}
              />
            </>
          )}
        </S.NoteTableContainer>
      </S.TableCellColumnWidth>
    </S.StyledVerbatimTableCell>
  )
}

export default NoteTableCell
