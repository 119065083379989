/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import omitBy from 'lodash/omitBy'
import isString from 'lodash/isString'
import pick from 'lodash/pick'
import mapValues from 'lodash/mapValues'
import isArray from 'lodash/isArray'
import defaults from 'lodash/defaults'
import omitDeep from 'omit-deep-lodash'
import { navigate, useLocation, WindowLocation } from '@reach/router'
import { HeaderState } from 'components/App/Providers/Header/Header'
import queryString from 'query-string'
import useQueued from 'hooks/useQueued'
import * as T from 'components/shared/VoCTimeMenu/types'
import {
  getDefaultTimeFilterRange,
  getDefaultTimeFilterStep,
  getRelativeValueIsValid,
} from 'utils/times/times'

const ASCII_COMMA = '%2C'

export interface Param {
  id: string
  initialValue?: any
}

export interface QueryParamsProps {
  params: Param[]
  onChange?: (newValues: Record<string, any>) => void
}

export const QS_PARAMS_ALLOW_LIST = [
  {
    urlFragment: 'client-analytics/errors/details',
    params: ['errorCode', 'errorType'],
  },
  {
    urlFragment: 'quantum-resources/event-cases',
    params: ['search'],
  },
  {
    urlFragment: 'quantum-resources/event-types',
    params: ['search'],
  },
  {
    urlFragment: 'quantum-resources/status',
    params: ['search', 'tab'],
  },
  {
    urlFragment: 'client-analytics/page-views/details',
    params: ['pageName', 'appSection'],
  },
  {
    urlFragment: 'client-analytics/apis/details',
    params: ['apiName', 'httpVerb', 'apiArchitecture', 'apiOperationName', 'apiOperationType'],
  },
  {
    urlFragment: 'client-analytics/apis/visit-ids',
    params: [
      'apiName',
      'httpVerb',
      'apiArchitecture',
      'apiOperationName',
      'apiOperationType',
      'responseText',
      'responseCode',
    ],
  },
  {
    urlFragment: 'client-analytics/apis/code-details',
    params: [
      'apiName',
      'responseCode',
      'httpVerb',
      'apiArchitecture',
      'apiOperationName',
      'apiOperationType',
    ],
  },
  {
    urlFragment: 'client-analytics/events/details',
    params: ['eventCaseID'],
  },
  {
    urlFragment: 'client-analytics/voice-of-customer/overview',
    params: ['appVersion'],
  },
  {
    urlFragment: 'voice-of-customer/digital-surveys/overview',
    params: ['appVersion'],
  },
  {
    urlFragment: 'client-analytics/voice-of-customer/overview/details',
    params: ['categoryID', 'manualCategoryID', 'userSelectedCategory'],
  },
  {
    urlFragment: 'voice-of-customer/digital-surveys/overview/details',
    params: ['categoryID', 'manualCategoryID', 'userSelectedCategory'],
  },
  {
    urlFragment: 'client-analytics/voice-of-customer/reviews',
    params: ['vocCategory', 'showPII', 'appVersion', 'surveyID', 'manualCategory'],
  },
  {
    urlFragment: 'voice-of-customer/digital-surveys/reviews',
    params: ['vocCategory', 'showPII', 'appVersion', 'surveyID', 'manualCategory'],
  },
  {
    urlFragment: 'client-analytics/voice-of-customer/edit-reviews',
    params: [
      'vocFeedbackID',
      'vocCategory',
      'showPII',
      'minVerbatimLength',
      'maxVerbatimLength',
      'surveyID',
      'favoritedFields',
      'manualCategory',
    ],
  },
  {
    urlFragment: 'voice-of-customer/digital-surveys/edit-reviews',
    params: [
      'vocFeedbackID',
      'vocCategory',
      'showPII',
      'minVerbatimLength',
      'maxVerbatimLength',
      'surveyID',
      'favoritedFields',
      'manualCategory',
    ],
  },
  {
    urlFragment: 'quantum-resources/event-cases/details', //LEGACY URL for redirect to work
    params: ['entityID', 'domain'],
  },
  {
    urlFragment: 'quantum-resources/schema',
    params: ['searchTerm'],
  },
  {
    urlFragment: 'quantum-resources/helix/requirements/decoder',
    params: ['decoderVersion'],
  },
  {
    urlFragment: 'data-sources/quantum/schema',
    params: ['searchTerm'],
  },
  {
    urlFragment: 'data-sources/qube/schema',
    params: ['searchTerm'],
  },
  {
    urlFragment: 'data-sources/airlytics/schema',
    params: ['searchTerm'],
  },
  {
    urlFragment: '/tools/sequence',
    params: ['dataSource', 'isTraceID'],
  },
  {
    urlFragment: '/tools/volt',
    params: [
      'visitId',
      'tcid',
      'ecid',
      'submit',
      'seqNumber',
      'validationType',
      'isRecent',
      'isIgnoredEnriched',
    ],
  },
  {
    urlFragment: 'client-analytics/release-summary/details',
    params: ['releaseVersion'],
  },
  {
    urlFragment: 'server-analytics/overview/details',
    params: ['apiName'],
  },
  {
    urlFragment: 'server-analytics/overview/response-code',
    params: ['responseCode', 'apiName'],
  },
  {
    urlFragment: 'client-analytics/release-summary/details',
    params: ['compareFeature', 'compareVersion'],
  },
  {
    urlFragment: '/searchwiki',
    params: ['terms', 'docID'],
  },
  {
    urlFragment: '/welcome',
    params: ['redirected'],
  },
  {
    urlFragment: '/',
    params: ['opUserCat', 'openFeedbackModal'],
  },
  {
    urlFragment: '/client-analytics/customer-journey',
    params: ['dateRange'],
  },
  {
    urlFragment: '/tools/work-orders/search',
    params: ['techMobileSearchValue'],
  },
  {
    urlFragment: '/client-analytics/experiment-snapshot',
    params: ['experimentSnapshotSearchValue'],
  },
  {
    urlFragment: '/client-analytics/experiment-snapshot/details',
    params: ['experimentUuid', 'activatedExperiments', 'variantUuids'],
  },
  {
    urlFragment: '/tools/cst',
    params: [''],
    removeDefaultParams: true,
  },
]

function useQueryStringParams(
  { params: validParams, onChange }: QueryParamsProps,
  state: HeaderState,
  validPeriods?: T.Period[],
  currentApps?: String[]
) {
  const location = useLocation()
  const [currentValidParams, setCurrentValidParams] = React.useState(validParams)
  const [currentURL, setCurrentURL] = React.useState(`${location.pathname}${location.search}`)
  const [currentPath, setCurrentPath] = React.useState(location.pathname)

  const [values, setValues] = React.useState(() => {
    return initializeState(validParams, location, state, currentURL, setCurrentURL)
  }) as any

  const resetToDefault = (days: number, removeThirtyDays: boolean) => {
    const defaultParams = {
      values: { period: '24h', step: 3600000 },
    }
    if (values?.period) {
      if (values.period.includes(':')) {
        const retention = new Date().setDate(new Date().getDate() - days)
        const toDate = values.period.split(':')
        if (Number(toDate[0] < retention)) batchUpdateValues(defaultParams)
      } else if (values.period === '30d' && removeThirtyDays) batchUpdateValues(defaultParams)
    }
  }

  React.useEffect(() => {
    if (currentApps?.some(app => app.toLowerCase().includes('specguide'))) {
      resetToDefault(16, true)
    } else if (currentApps?.some(app => app.toLowerCase().includes('oneapp'))) {
      resetToDefault(22, false)
    }
  }, [currentApps])

  React.useEffect(() => {
    if (location.pathname.includes('customer-journey')) {
      setCurrentPath(location.pathname)
      setCurrentURL(`${location.pathname}?${location.search}`)
      return
    }
  }, [
    currentURL,
    location.pathname,
    location.search,
    updateValues,
    validParams,
    values,
    currentPath,
  ])

  const batchUpdateValues = useQueued(updateValues)

  const isVoiceOfCustomerPath = (path: string) => {
    return path.includes('voice-of-customer')
  }

  function updateValues(
    newParamValues: Record<string, any>,
    didPathOrParamsChange: boolean = false
  ) {
    if (newParamValues.period === '24h' && isVoiceOfCustomerPath(currentPath))
      newParamValues.period = '1d'
    if (isNil(newParamValues?.periodOffset) && values?.periodOffset) delete values.periodOffset

    const { nextQueryStringObj, nextQueryString } = getNextQueryString(
      newParamValues,
      values,
      validParams,
      location,
      didPathOrParamsChange,
      currentURL,
      validPeriods
    )

    onChange && onChange(nextQueryStringObj)

    const nextURL = `${location.pathname}${nextQueryString ? `?${nextQueryString}` : ''}`

    setCurrentValidParams(validParams)

    if (currentURL !== nextURL) {
      setValues(nextQueryStringObj)
      setCurrentURL(nextURL)

      navigate(`${location.pathname}${nextQueryString ? `?${nextQueryString}` : ''}`, {
        replace: true,
      })

      if (didPathOrParamsChange) setCurrentPath(location.pathname)
    }
  }

  const didPathChange = location.pathname !== currentPath
  const didValidParamsChange = !isEqual(
    omitDeep(currentValidParams, 'options'),
    omitDeep(validParams, 'options')
  )

  if (didPathChange || didValidParamsChange) {
    const { nextQueryStringObj } = getNextQueryString(
      queryString.parse(location.search.split(ASCII_COMMA).join(','), {
        arrayFormat: 'bracket-separator',
        arrayFormatSeparator: ',',
        parseBooleans: true,
        parseNumbers: true,
      }),
      values,
      validParams,
      location,
      true,
      currentURL,
      validPeriods
    )
    if (!isEmpty(nextQueryStringObj) && state.areFiltersReady) {
      updateValues(nextQueryStringObj, true)
      return {
        values: nextQueryStringObj,
        updateValues,
        batchUpdateValues,
      }
    }

    updateValues(nextQueryStringObj, true)
    return {
      values: nextQueryStringObj,
      updateValues,
      batchUpdateValues,
    }
  }

  return {
    values,
    updateValues,
    batchUpdateValues,
  }
}

export function parseToStringArray(value: string | string[]) {
  return isString(value) ? [value] : value
}

export function getAppVersionAsStringArray(initialValues: { appVersion?: string | string[] }) {
  return initialValues?.appVersion
    ? { appVersion: parseToStringArray(initialValues.appVersion) }
    : {}
}

export function getAppVersionValues(initialValues: { appVersion?: string | string[] }) {
  return {
    ...initialValues,
    ...getAppVersionAsStringArray(initialValues),
  }
}

export function initializeState(
  validParams: Param[],
  location: WindowLocation,
  state: HeaderState,
  currentURL: string,
  setCurrentURL: React.Dispatch<React.SetStateAction<string>>
) {
  const initialValues = getInitialValues(validParams, location, state.areFiltersReady)

  const nextQueryString = queryString.stringify(initialValues, {
    arrayFormat: 'bracket-separator',
    arrayFormatSeparator: ',',
    encode: true,
  })

  const nextURL = `${location.pathname}?${nextQueryString}`

  if (nextURL !== currentURL) {
    if (state.areFiltersReady) {
      navigate(nextURL, { replace: true })
    }
    setCurrentURL(nextURL)
  }

  return getAppVersionValues(initialValues)
}

export function getInitialValues(
  validParams: Record<string, any>[],
  location: WindowLocation,
  areFiltersReady: boolean = true
) {
  let initialValues = validParams.reduce((accum, { id, initialValue }) => {
    if (initialValue !== undefined) {
      return { ...accum, [id]: initialValue }
    }
    return accum
  }, {} as Record<string, any>)
  if (!initialValues.partialData) {
    initialValues.partialData = undefined
    location.search = location.search
      .split('&')
      .filter(param => !param.includes('partialData=false'))
      .join('&')
  }
  const currentParamValues = queryString.parse(location.search.split(ASCII_COMMA).join(','), {
    arrayFormat: 'bracket-separator',
    arrayFormatSeparator: ',',
    parseBooleans: true,
    parseNumbers: true,
  })

  const allValues = { ...initialValues, ...currentParamValues }

  const allValidValues = pick(allValues, getAllValidParams(validParams, location))

  return areFiltersReady ? allValidValues : allValues
}

// Checks if particular param values are still valid
export const filterValidParamValues = (
  nextValues: Record<string, any> = {},
  validParams: Record<string, any>[],
  validPeriods?: T.Period[],
  location?: WindowLocation
) => {
  const isVoC = location?.pathname?.includes('/voice-of-customer')
  return mapValues(nextValues, function (paramValue, paramId) {
    if (paramId === 'groupBy') {
      const groupByToCheck = validParams.find((paramObj: any) => paramObj.id === paramId)
      const isGroupByValueValid =
        groupByToCheck?.options &&
        groupByToCheck.options.find((option: any) => option.value === nextValues[paramId])

      if (!!isGroupByValueValid) {
        return paramValue
      } else return groupByToCheck?.initialValue
    } else if (paramId === 'period') {
      if (isVoC && paramValue === '24h') return '1d'
      const isPeriodValueValid =
        validPeriods?.some((option: T.Period) => {
          return option.value === nextValues[paramId]
        }) ||
        (isVoC && getRelativeValueIsValid(nextValues[paramId]))
      if (isPeriodValueValid || nextValues[paramId].includes(':')) {
        return paramValue
      } else {
        return getDefaultTimeFilterRange()
      }
    } else if (paramId === 'step') {
      const isStepValueValid = validPeriods?.find((option: T.Period) => {
        return option.steps?.some((step: { step: number | string }) => step?.step === paramValue)
      })
      if (!!isStepValueValid) {
        return paramValue
      } else {
        return getDefaultTimeFilterStep()
      }
    } else if (paramId === 'periodOffset') {
      if (Number.isInteger(parseInt(paramValue))) return paramValue
      else return '1'
    } else return paramValue
  })
}

export function getNextQueryString(
  newParamValues: Record<string, any>,
  currentValues: Record<string, any> = {},
  validParams: Record<string, any>[],
  location: WindowLocation,
  didPathOrParamsChange: boolean,
  currentURL: string,
  validPeriods?: T.Period[]
): {
  nextQueryStringObj: Record<string, any>
  nextQueryString: string
} {
  if (newParamValues.environment && newParamValues.environment !== currentValues.environment) {
    delete currentValues.appVersion
  }

  if (!newParamValues?.partialData) {
    newParamValues.partialData = undefined
    newParamValues.isPartialData = undefined
    currentValues.partialData = undefined
  }
  const validCurrentValues = pick(currentValues, getAllValidParams(validParams, location))
  const validNewParams = pick(newParamValues, getAllValidParams(validParams, location))
  const initialValuesForCurrentValidParams = didPathOrParamsChange
    ? getInitialValues(validParams, location)
    : {}
  const nextValues = defaults(
    {},
    validNewParams,
    validCurrentValues,
    initialValuesForCurrentValidParams
  )
  const filteredNextValues = didPathOrParamsChange
    ? filterValidParamValues(nextValues, validParams, validPeriods, location)
    : nextValues

  const currentEntityID = currentURL.split('/')[1]
  const nextEntityID = `${location.pathname}`.split('/')[1]
  const paramsToReset =
    currentEntityID !== nextEntityID
      ? validParams.filter((filter: any) => {
          return filter.resetOnEntityChange
        })
      : []

  const nextQueryStringObj = omitBy(
    filteredNextValues,
    (val, key) =>
      isNil(val) ||
      (isString(val) && val.length === 0) ||
      paramsToReset.some(param => param.id === key) ||
      (isArray(val) && val.length === 1 && val[0].length === 0)
  )

  const nextQueryString = queryString.stringify(nextQueryStringObj, {
    arrayFormat: 'bracket-separator',
    arrayFormatSeparator: ',',
    encode: true,
  })

  return { nextQueryStringObj, nextQueryString }
}

export function getAllValidParams(validParams: Record<string, any>[], location: WindowLocation) {
  let shouldRemoveDefaultParams = false
  const additionalValidParams =
    QS_PARAMS_ALLOW_LIST.reduce((acc: any, paramObj: any) => {
      if (location.pathname.includes(paramObj.urlFragment)) {
        shouldRemoveDefaultParams = paramObj.removeDefaultParams || false
        return acc.concat(paramObj.params)
      }
      return acc
    }, []) || []

  const validParamIDs = validParams.map(param => param.id)
  return shouldRemoveDefaultParams
    ? additionalValidParams
    : validParamIDs.concat(additionalValidParams)
}

export default useQueryStringParams
