import { useCallback, useEffect, useRef, useState, ReactNode } from 'react'
import { isNil, isEmpty } from 'lodash'
import Popper from '@material-ui/core/Popper'
import { Item } from './DropdownItem'
import { DropdownItems } from './DropdownItems'
import * as S from './styles'
import { useDarkMode } from 'components/App/Providers/DarkMode'
import Typography from 'components/shared/typography'
import Flex from 'components/shared/flex'
import { element } from 'components/shared/utils/spacing'
import { colors } from 'components/shared/utils/colors'
import Icon from 'components/shared/icon'
import { Badge } from 'components/shared/Badge'

type Label = string | number

export type Items = Omit<Item, 'isSelected' | 'isDarkMode' | 'width'>[]

interface Selected {
  label?: Label
  value: any
}

export interface DropdownProps {
  /** An optional label for the dropdown */
  label?: Label
  /** Callback function that is invoked when the dropdown value changes */
  handleChange?: (value: any, label?: Label) => void
  /** Takes the shape of { label: string; value: string | number; onClick?: () => void; icon?: IconName; iconColor?: string } */
  items: Items
  allItems?: Items
  /** Placeholder text when a selection has not yet been made */
  placeholder?: string
  /** Disable the dropdown. Defaults to false */
  isDisabled?: boolean
  /** Allows using inline styles. Defaults to false */
  isInline?: boolean
  /** Turns the dropdown into a controlled component. Cannot be used concurrently with initialSelected */
  selected?: any
  /** Specify an initial value */
  defaultSelected?: any
  /** If populated, a badge will be shown to the right of the label (before the chevron) */
  labelBadgeText?: string
  /** If populated, a badge will be shown to the right of the label (before the chevron) */
  labelBadgeValue?: number
  /** Badge Text Color */
  labelBadgeColor?: string
  /** Badge Background Color */
  labelBadgeBackgroundColor?: string
  /** A minimum width for the dropdown to occupy. Can be a number in pixels or a string in any unit of your choosing. Defaults to fit content */
  minWidth?: number | string
  /** Adds data-test Identifier for Testing purposes. */
  dataTest?: string
  /** Enables Dropdown to use Popper dropdown */
  usePopperDropdown?: boolean
}

export const Dropdown = ({
  label,
  handleChange,
  items,
  allItems,
  placeholder,
  isDisabled = false,
  isInline = false,
  defaultSelected,
  selected: controlledSelected,
  labelBadgeText,
  labelBadgeValue,
  labelBadgeColor,
  labelBadgeBackgroundColor,
  minWidth,
  dataTest,
  usePopperDropdown,
  ...props
}: DropdownProps) => {
  const { isDarkMode } = useDarkMode()
  const dropdownRef = useRef<any>()
  const inputContainerRef = useRef()
  const [isOpen, setIsOpen] = useState(false)
  const [selected, setSelected] = useState<any>(
    defaultSelected !== undefined ? { value: defaultSelected } : null
  )
  const dataTestID = props['data-testid'] || 'dropdown'

  const actualSelected = controlledSelected === undefined ? selected : { value: controlledSelected }

  const handleOpen = useCallback(() => {
    if (isDisabled) return
    setIsOpen(true)
  }, [setIsOpen, isDisabled])

  const handleSelect = useCallback(
    (value: any, label: Label) => {
      setIsOpen(false)
      if (value !== 'backdropClick') {
        setSelected({ label, value })
        handleChange && handleChange(value, label)
      }
    },
    [setIsOpen, handleChange, isDisabled]
  )

  const closeDropdown = useCallback(
    (e: MouseEvent) => {
      if (dropdownRef && !dropdownRef?.current.contains(e.target as Node)) {
        setIsOpen(false)
      }
    },
    [setIsOpen]
  )

  useEffect(() => {
    window.addEventListener('mousedown', closeDropdown)
    return () => window.removeEventListener('mousedown', closeDropdown)
  }, [closeDropdown])

  const hasSelection = !isNil(actualSelected)
  const inputWidth = dropdownRef?.current?.offsetWidth
  const hasPlaceholderOrSelection = hasSelection || !isEmpty(placeholder)
  const isInlineWithPlaceholderOrSelection = isInline && hasPlaceholderOrSelection
  const Label = () => (
    <>
      {label && (
        <Typography
          data-testid={`${dataTestID}-label`}
          color={getLabelFontColor(isDisabled, isDarkMode)}
          onClick={handleOpen}
        >
          {label}
          {isInlineWithPlaceholderOrSelection && ':'}
        </Typography>
      )}
      {!isEmpty(labelBadgeText) && (
        <Flex margin={`0 0 0 ${element.xs}`}>
          <Badge
            text={labelBadgeText}
            value={labelBadgeValue}
            color={labelBadgeColor ?? colors.darkMode.text}
            backgroundColor={labelBadgeBackgroundColor ?? colors.blue20}
          />
        </Flex>
      )}
    </>
  )

  const isLabelInside = label && isInline && !hasPlaceholderOrSelection
  const dropdownItemsProps = {
    actualSelected: actualSelected?.value,
    handleSelect,
    isDarkMode,
    isOpen,
    items,
    hasSelection,
    inputWidth,
  }

  return (
    <S.DropdownContainer
      ref={dropdownRef}
      {...props}
      data-testid={dataTestID}
      $position={usePopperDropdown ? 'unset' : 'relative'}
    >
      <S.InputContainer
        $isInline={isInline}
        $hasPlaceholderOrSelection={hasPlaceholderOrSelection}
        $isDisabled={isDisabled}
        ref={inputContainerRef as any}
      >
        {!isLabelInside && <Label />}
        <S.EntryField
          $isOpen={isOpen}
          onClick={handleOpen}
          $isDisabled={isDisabled}
          $isInline={isInline}
          $isDarkMode={isDarkMode}
          $minWidth={typeof minWidth === 'number' ? `${minWidth}px` : (minWidth as any)}
        >
          <Typography
            data-testid={`${dataTestID}-selected`}
            data-test={dataTest}
            color={getInputFontColor(hasSelection, isDisabled, isDarkMode)}
          >
            {hasSelection ? getSelectedLabel(actualSelected, items, allItems) : placeholder}
          </Typography>
          {isLabelInside && <Label />}
          <Icon
            data-testid={`${dataTestID}-chevron`}
            name={isOpen ? 'ChevronUp' : 'ChevronDown'}
            size="xs"
            margin={isInline ? { left: element.xs } : {}}
          />
        </S.EntryField>
      </S.InputContainer>
      {usePopperDropdown ? (
        inputContainerRef &&
        isOpen && (
          <Popper
            anchorEl={inputContainerRef.current}
            placement="bottom"
            disablePortal={true}
            style={{ zIndex: 1500 }}
            modifiers={{
              flip: {
                enabled: true,
              },
              preventOverflow: {
                enabled: true,
              },
            }}
            open
          >
            <DropdownItems {...dropdownItemsProps} />
          </Popper>
        )
      ) : (
        <DropdownItems {...dropdownItemsProps} position="absolute" />
      )}
    </S.DropdownContainer>
  )
}

const getSelectedLabel = (selected: { label?: Label; value: any }, items: Items, allItems?: Items): ReactNode => {
  return selected?.label || items.find(item => item.value === selected.value)?.label || allItems?.find(item => item.value === selected.value)?.label
}

const getLabelFontColor = (isDisabled: boolean, isDarkMode: boolean): string => {
  if (isDarkMode) {
    return isDisabled ? colors.gray30 : colors.white
  }

  return isDisabled ? colors.gray25 : colors.black
}

const getInputFontColor = (
  hasSelection: boolean,
  isDisabled: boolean,
  isDarkMode: boolean
): string => {
  if (isDisabled) return colors.gray25
  if (isDarkMode) {
    return hasSelection ? colors.white : colors.gray25
  }

  return hasSelection ? colors.black : colors.gray25
}
