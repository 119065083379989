import React from 'react'
import {
  List,
  ListItem,
  Menu,
  MenuItem,
  Divider,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import { colors } from 'components/shared/utils/colors'
import Modal from 'components/shared/Modal'
import useAuth from 'hooks/useAuth'
import usePrismUser from 'hooks/usePrismUser'
import UserAvatar from './UserAvatar'
import { readableColor } from 'polished'
import * as S from './styles'

function UserMenu() {
  const prismUser = usePrismUser()
  const user = useAuth().user
  const userImage = prismUser?.avatar || ''
  const backgroundColor = prismUser?.avatarBackgroundColor
    ? prismUser?.avatarBackgroundColor
    : colors.gray10
  const preferredInitials = prismUser?.preferredInitials ? prismUser?.preferredInitials : ''

  const [userPreferredInitials, setUserPreferredInitial] = React.useState(preferredInitials)
  const [userAvatarBackgroundColor, setUserAvatarBackgroundColor] = React.useState(backgroundColor)
  const [anchorEl, setAnchorEl] = React.useState(null)
  const isMenuOpen = Boolean(anchorEl)
  const [userImg, setUserImg] = React.useState(userImage)
  const [errorMessage, setErrorMessage] = React.useState('')
  const [open, setOpen] = React.useState(false)
  const [initialInput, setInitialInput] = React.useState('')
  const [isBackgroundColorPickerOpen, setIsBackgroundColorPickerOpen] = React.useState(false)

  const handleMenuOpen = (event: any) => setAnchorEl(event.currentTarget)
  const handleMenuClose = () => setAnchorEl(null)
  const handleError = (message: string) => setErrorMessage(message)
  const handleModalState = () => setOpen(!open)

  function handleInitialChange(e: any) {
    return e.target.value.length < 4 ? setInitialInput(e.target.value.toUpperCase()) : ''
  }

  function handleInitialChangeCancel() {
    setInitialInput('')
    handleModalState()
    handleMenuClose()
  }

  function handleInitialChangeSubmit() {
    prismUser.update({ preferredInitials: initialInput.toUpperCase() })
    setUserPreferredInitial(initialInput.toUpperCase())
    setInitialInput('')
    handleModalState()
    handleMenuClose()
  }

  function handleFileUpload(e: any) {
    const file = e.target?.files[0] ?? {}
    if (!file?.type.includes('image/')) handleError('File must be an image')
    else if (file.size > 1000000) handleError('Image must be less than 1mb')
    else {
      const fr = new FileReader()
      fr.onload = (data: Record<string, any>) => {
        prismUser.update({ avatar: data.currentTarget.result })
        setUserImg(data.currentTarget.result)
      }
      fr.readAsDataURL(file)
      if (errorMessage) setErrorMessage('')
      handleMenuClose()
    }
  }

  function handleAvatarRemoval() {
    prismUser.update({ avatar: '' })
    setUserImg('')
    handleMenuClose()
  }

  function handleEnter(e: any) {
    if (e?.keyCode === 13) {
      return handleInitialChangeSubmit()
    }
  }

  function determineInitialFontSize(initials: string): number {
    return initials.length > 2 ? 14 : 16
  }

  function toggleIsBackgroundColorPickerOpen() {
    setOpen(false)
    setAnchorEl(null)
    setIsBackgroundColorPickerOpen(!isBackgroundColorPickerOpen)
  }

  function handleBackgroundColorChange(color: any) {
    setUserAvatarBackgroundColor(color)
  }

  function handleSubmitBackgroundColorChange(color: string) {
    prismUser.update({ avatarBackgroundColor: color })
    setIsBackgroundColorPickerOpen(!isBackgroundColorPickerOpen)
  }

  function handleCancelBackgroundColorChange() {
    setUserAvatarBackgroundColor(prismUser?.avatarBackgroundColor || colors.gray10)
    setIsBackgroundColorPickerOpen(!isBackgroundColorPickerOpen)
  }

  return (
    <List style={{ margin: 0, padding: 0 }}>
      <ListItem
        button
        onClick={handleMenuOpen}
        data-pho="userMenuButton"
        style={{ paddingTop: 2, paddingBottom: 2 }}
      >
        <UserAvatar
          user={user}
          userImage={userImg}
          userInitials={userPreferredInitials}
          style={{
            marginTop: 1,
            width: 29,
            height: 29,
            top: 0,
            fontSize: determineInitialFontSize(userPreferredInitials),
            position: 'relative',
          }}
          backgroundColor={userAvatarBackgroundColor}
          textColor={readableColor(userAvatarBackgroundColor)}
        />
        <S.Caret open={isMenuOpen} />
      </ListItem>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        id="user-menu"
        keepMounted
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuOpen}
        onClose={handleMenuClose}
      >
        <MenuItem disabled>{user?.name}</MenuItem>
        <Divider variant="middle" />
        <MenuItem>
          <S.Label>
            <S.Input type="file" accept="image/*" onChange={handleFileUpload} />
            {userImg.length ? 'Change Photo' : 'Upload Photo'}
          </S.Label>
        </MenuItem>
        {!userImg.length ? (
          <span>
            <MenuItem>
              <S.Label>
                <S.Input type="button" onClick={handleModalState} />
                {userPreferredInitials ? 'Update Custom Initials' : 'Add Custom Initials'}
              </S.Label>
              <Dialog open={open} onClose={handleModalState} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Update Your Initials</DialogTitle>
                <DialogContent>
                  <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Add Up to 3 Characters"
                    type="text"
                    onChange={handleInitialChange}
                    onKeyDown={handleEnter}
                    value={initialInput}
                    fullWidth
                  />
                </DialogContent>
                <DialogActions>
                  <S.ColorButton onClick={handleInitialChangeCancel} variant="borderless">
                    Cancel
                  </S.ColorButton>
                  <S.ColorButton onClick={handleInitialChangeSubmit}>Submit</S.ColorButton>
                </DialogActions>
              </Dialog>
            </MenuItem>
            <MenuItem>
              <S.Label>
                <S.Input type="button" onClick={() => toggleIsBackgroundColorPickerOpen()} />
                Change Background Color
              </S.Label>
              <S.Label>
                <S.Input type="button" onClick={() => toggleIsBackgroundColorPickerOpen()} />
                <Modal
                  open={isBackgroundColorPickerOpen}
                  title={`Choose your background color`}
                  justifyTitle="center"
                  xClick={() => handleCancelBackgroundColorChange()}
                  content={
                    isBackgroundColorPickerOpen && (
                      <>
                        <S.ColorPicker
                          color={userAvatarBackgroundColor}
                          onChange={color => handleBackgroundColorChange(color)}
                        />
                        <S.ColorInput
                          color={userAvatarBackgroundColor}
                          onChange={color => handleBackgroundColorChange(color)}
                          prefixed
                        />
                        <S.ColorActions>
                          <S.ColorButton
                            onClick={() => handleCancelBackgroundColorChange()}
                            variant="borderless"
                          >
                            Cancel
                          </S.ColorButton>
                          <S.ColorButton
                            onClick={() =>
                              handleSubmitBackgroundColorChange(userAvatarBackgroundColor)
                            }
                          >
                            Submit
                          </S.ColorButton>
                        </S.ColorActions>
                      </>
                    )
                  }
                  justifyActionsContent="center"
                  onClose={() => handleCancelBackgroundColorChange()}
                  data-pho="changeBackgroundColorModal"
                />
              </S.Label>
            </MenuItem>
          </span>
        ) : (
          ''
        )}
        {userImg.length ? (
          <span>
            <Divider variant="middle" />
            <MenuItem>
              <S.Label>
                <S.Input type="button" onClick={handleAvatarRemoval} />
                Remove Photo
              </S.Label>
            </MenuItem>
          </span>
        ) : (
          ''
        )}
        <S.ErrorContainer style={errorMessage ? { display: 'block' } : { display: 'none' }}>
          {errorMessage}
        </S.ErrorContainer>
      </Menu>
    </List>
  )
}

export default UserMenu
