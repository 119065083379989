import React from 'react'
import { Link, useLocation } from '@reach/router'
import Entity from 'components/shared/Entity'
import { getRouteWithQueries } from 'utils'

function PrismAdminList({ handleClose }: DocsListProps) {
  const location = useLocation()

  const prismAdminList = [
    {
      name: 'Prism Usage',
      id: 'prismusage',
      customURLFilters: `${location.search}`,
    },
  ]

  return (
    <div>
      {prismAdminList.map(item => {
        const to = item.customURLFilters ? getRouteWithQueries(`/prismadmin/${item.id}${item.customURLFilters}`, location, []) : getRouteWithQueries(`/prismadmin/${item.id}`, location, [])

        return (<Link
          key={item.id}
          onClick={handleClose}
          to={to}
        >
          <Entity
            title={item.name}
            data-pho={item.name}
          />
        </Link>
        )
      })}
    </div>
  )
}

interface DocsListProps {
  handleClose: (
    event:
      | React.MouseEvent<HTMLAnchorElement, MouseEvent>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void
}

export default PrismAdminList
