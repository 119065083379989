import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import omit from 'lodash/omit'
import Flex from '../flex/Flex'
import { Button } from 'components/shared/Button'
import { inputTypes } from 'utils/sharedUtils'
import InputAdornment from '@material-ui/core/InputAdornment'
import FormControl from '@material-ui/core/FormControl'
import Tooltip from '@material-ui/core/Tooltip'
import OptionGroup, { OptionProps } from 'components/shared/OptionGroup'
import { FormLabel } from 'components/shared/OptionGroup/styled'
import Autocomplete from 'components/shared/Autocomplete'
import SeqRangeFilter from 'screens/tools/sequence/shared/SeqRangeFilter'
import Icon from '../icon/Icon'
import ExperimentAndVariantUuidsFilter from './ExperimentAndVariantUuidsFilter'
import VocCategoryGroupFilter from './VocCategoryGroupFilter'
import VoCActivationMethodFilter from './VoCActivationMethodFilter'
import VoCActivationMethodPathFilter from './VoCActivationMethodPathFilter'
import VoCMiddleCategoryFilter from './VoCMiddleCategoryFilter'
import VocManualCategoryFilter from './VocManualCategoryFilter'
import RangeFilter from './RangeFilter'
import * as U from './utils'
import * as S from './styled'
import * as T from './types'
import { GET_FEEDBACK_BY_ID } from 'components/voiceOfCustomer/queries'
import {
  ApolloClient,
  NormalizedCacheObject,
  useLazyQuery,
  WatchQueryFetchPolicy,
} from '@apollo/client'
import { getVocClient } from 'components/voiceOfCustomer/utils'
import useFilterValues from 'hooks/useFilterValues'
import Alert from 'components/shared/Alert'
import useApps from 'hooks/useApps'

const OMIT_FILTERS = ['vocActivationMethod', 'vocActivationTypeOutcome']

function FilterMenuContent({
  handleClose,
  filters,
  values,
  updateValues,
  batchDelay = 500,
  suppressCard = false,
  setFilterValues,
  filterValues = {},
  useFilterApplyButton,
}: T.FilterMenuContentProps) {
  const { currentApps } = useApps()
  const [disableApplyButton, setDisableApplyButton] = React.useState(false)
  const [loadingApplyButton, setLoadingApplyButton] = React.useState(false)
  const filterCount = filters.length
  const { environment } = useFilterValues()
  const handleCloseClick = React.useCallback(() => handleClose && handleClose(), [handleClose])
  const client = getVocClient(environment as string, true)

  const query = GET_FEEDBACK_BY_ID
  const { vocFeedbackID }: T.FilterValues = filterValues
  const queryOptions = {
    client,
    variables: { _id: vocFeedbackID },
    fetchPolicy: 'no-cache',
  }

  const [filterErrorMsg, setFilterErrorMsg]: [string, Dispatch<SetStateAction<string>>] =
    useState('')

  const [getFeedbackQuery] = useLazyQuery(
    query,
    queryOptions as {
      client: ApolloClient<NormalizedCacheObject>
      variables: { _id: string }
      fetchPolicy: WatchQueryFetchPolicy | undefined
    }
  )
  const appType = currentApps?.[0]?.split('@')[1];

  useEffect(() => {
    if (vocFeedbackID) {
      setFilterErrorMsg('')
    }
  }, [vocFeedbackID])

  const validateFeedback = async () => {
    if (!vocFeedbackID) return true
    setLoadingApplyButton(true)
    try {
      const { data } = await getFeedbackQuery()
      setLoadingApplyButton(false)
      if (!data) {
        setFilterErrorMsg('Invalid Feedback ID')
        return false
      }
    } catch (error) {
      setFilterErrorMsg('Invalid Feedback ID')
      setLoadingApplyButton(false)
      return false
    }
    return true
  }

  async function handleApplyFilters() {
    if (await validateFeedback()) {
      updateValues && updateValues(filterValues || {})
    }
  }

  if (suppressCard) {
    return (
      <>
        {filters.map(filter => (
          <S.FilterContainer key={filter.id}>
            <FilterType
              key={`Filter_Type_${values.length}_${filter.id}_${values[filter.id]?.length}`}
              filter={filter}
              value={values[filter.id]}
              updateValues={updateValues}
              batchDelay={batchDelay}
              setFilterValues={setFilterValues}
              filterValues={filterValues}
              useFilterApplyButton={useFilterApplyButton}
            />
          </S.FilterContainer>
        ))}
      </>
    )
  }

  return (
    <S.StyledCard
      elevation={3}
      padding="1rem"
      minWidth={filterCount > 1 ? '42rem' : '20rem'}
      minHeight="12rem"
      width="100%"
      height="100%"
    >
      {filterErrorMsg && (
        <Alert
          children={filterErrorMsg}
          variant="page-error"
          onClose={() => setFilterErrorMsg('')}
        />
      )}
      {useFilterApplyButton && (
        <Flex justifyContent="flex-start" width="100%">
          <Button
            loading={loadingApplyButton}
            onClick={handleApplyFilters}
            variant="primary"
            disabled={
              loadingApplyButton ||
              U.areFilterValuesEqual(
                omit(filterValues, OMIT_FILTERS),
                omit(values, OMIT_FILTERS)
              ) ||
              disableApplyButton ||
              Boolean(filterErrorMsg)
            }
          >
            Apply Filters
          </Button>
        </Flex>
      )}
      {filters.map(filter => (
        <S.FilterContainer
          key={filter.id}
          fullWidth={filter.fullWidth}
          isExperimentAndVariantUuidsFilter={filter.type === 'EXPERIMENT-AND-VARIANT-UUIDS'}
        >
          {!(filter.id === 'environment' && appType === 'WebSurvey') ?
            (<FilterType
              key={`Filter_Type_${values.length}_${filter.id}_${values[filter.id]?.length}`}
              filter={filter}
              value={values[filter.id]}
              updateValues={updateValues}
              batchDelay={batchDelay}
              setFilterValues={setFilterValues}
              filterValues={filterValues}
              useFilterApplyButton={useFilterApplyButton}
              setDisableApplyButton={setDisableApplyButton}
            />) : <S.EndpointFilterMessage>CST applications are only available at the Endpoint they were created in.</S.EndpointFilterMessage>}
        </S.FilterContainer>
      ))}
      <S.CloseIcon onClick={handleCloseClick} size="sm" icon="X" />
    </S.StyledCard>
  )
}

function FilterType({
  filter,
  value,
  updateValues,
  batchDelay,
  setFilterValues,
  filterValues,
  useFilterApplyButton,
  setDisableApplyButton,
}: {
  filter: T.Filter
  value: any
  updateValues?: (newValues: Record<string, any>) => void
  batchDelay?: number
  setFilterValues?: Function
  filterValues: T.FilterValues
  useFilterApplyButton?: boolean
  setDisableApplyButton?: (disable: boolean) => void
}) {
  const [timeoutList, setTimeoutList] = React.useState<any>({})

  const handleChange = (id: string) => (newValue: any) => {
    if (useFilterApplyButton) {
      handleChangeWithApplyClick(id, newValue)
    } else {
      clearTimeout(timeoutList[id])
      const timeout = setTimeout(function () {
        if (filter.shouldUpdateLocalStorage) {
          localStorage.setItem(id, get(newValue, 'value', newValue))
        }
        updateValues && updateValues({ [id]: get(newValue, 'value', newValue) })
      }, batchDelay)
      setTimeoutList({ ...timeoutList, [id]: timeout })
    }
  }

  function handleChangeWithApplyClick(id: string, newValue: any) {
    if (id === 'activationMethod') {
      setFilterValues &&
        setFilterValues({
          ...filterValues,
          [id]: get(newValue, id, []),
          vocActivationMethod: [],
          vocActivationTypeOutcome: [],
        })
    } else {
      setFilterValues &&
        setFilterValues({ ...filterValues, [id]: get(newValue, 'value', newValue) })
    }
  }

  function handleMultipleFilterChange(values: T.FilterValues) {
    if (useFilterApplyButton) {
      setFilterValues && setFilterValues({ ...filterValues, ...values })
    } else {
      updateValues && updateValues(values)
    }
  }

  const handleRangeChange = (id: string, newValue: any) => {
    if (useFilterApplyButton) {
      setFilterValues &&
        setFilterValues({ ...filterValues, [id]: get(newValue, 'value', newValue) })
    } else {
      updateValues && updateValues({ [id]: get(newValue, 'value', newValue) })
    }
  }

  switch (filter.type) {
    case inputTypes.CHECKBOX: {
      return (
        <OptionGroup
          key={filter.id}
          label={filter.label}
          defaultValue={value}
          options={filter.options as OptionProps[]}
          onChange={handleChange(filter.id)}
          data-pho="filterMenuCheckboxInput"
          useGetOptions={filter.useGetOptions}
        />
      )
    }

    case inputTypes.RADIO_BUTTON: {
      return (
        <OptionGroup
          key={filter.id}
          label={
            filter.filterDescription ? (
              <>
                {filter.label}
                <Tooltip title={filter.filterDescription} placement="top" arrow>
                  <span>
                    <Icon name="InfoCircle" size="xs" margin={{ left: 'xxs' }} />
                  </span>
                </Tooltip>
              </>
            ) : (
              filter.label
            )
          }
          defaultValue={value}
          options={filter.options as OptionProps[]}
          onChange={handleChange(filter.id)}
          isRadioButton
          useGetOptions={filter.useGetOptions}
          data-pho="filterMenuRadioButtonInput"
        />
      )
    }

    case 'NUMBER': {
      return (
        <S.Input
          key={`Number_Input_${filter.id}`}
          label={filter.label}
          value={value}
          onChange={handleChange(filter.id)}
          type="number"
          data-pho="filterMenuNumberInput"
        />
      )
    }

    case 'AUTOCOMPLETE': {
      return (
        <Autocomplete
          canSelectMultipleValues={filter.canSelectMultipleValues}
          label={filter.label}
          defaultValue={value}
          onChange={handleChange(filter.id)}
          options={filter.options}
          useGetOptions={filter.useGetOptions}
          getOptionLabel={filter.getOptionLabel}
          realtimeDashboardID={filter.realtimeDashboardID}
          isSequenceEventTypeFilter={filter.isSequenceEventTypeFilter}
          invertedLabel={filter.invertedLabel}
          fullWidth={filter.fullWidth}
          fullHeight
          width={S.FILTER_WIDTH}
          showTagTooltip
          data-pho="filterMenuAutoCompleteInput"
        />
      )
    }
    case 'EXPERIMENT-AND-VARIANT-UUIDS': {
      return <ExperimentAndVariantUuidsFilter filter={filter} />
    }
    case 'VOC-CATEGORY-GROUP': {
      return (
        <VocCategoryGroupFilter
          filter={filter}
          onChange={handleMultipleFilterChange}
          filterValues={filterValues}
        />
      )
    }
    case 'VOC-ACTIVATION_METHOD': {
      return (
        <VoCActivationMethodFilter
          filter={filter}
          onChange={handleMultipleFilterChange}
          filterValues={filterValues}
        />
      )
    }
    case 'VOC-ACTIVATION-METHOD-PATH': {
      return (
        <VoCActivationMethodPathFilter
          filter={filter}
          onChange={handleChange(filter.id)}
          filterValues={filterValues}
        />
      )
    }
    case 'VOC-MANUAL-CATEGORY': {
      return (
        <VocManualCategoryFilter
          filter={filter}
          handleMultipleFilterChange={handleMultipleFilterChange}
          filterValues={filterValues}
        />
      )
    }

    case 'VOC-MIDDLE-CATEGORY': {
      return (
        <VoCMiddleCategoryFilter
          filter={filter}
          onChange={handleMultipleFilterChange}
          filterValues={filterValues}
        />
      )
    }
    case 'INPUT': {
      return (
        <InputFilter
          label={filter.label}
          defaultValue={value}
          submitValue={handleChange(filter.id)}
          data-pho="filterMenuTextInput"
        />
      )
    }

    case 'SEQUENCE-RANGE': {
      return (
        <SeqRangeFilter
          label={filter.label}
          filterId={filter.id}
          handleRangeChange={handleRangeChange}
          defaultValue={value}
        />
      )
    }

    case 'RANGE': {
      return (
        <>
          <RangeFilter
            label={filter.label}
            filterId={filter.id}
            handleRangeChange={handleRangeChange}
            defaultValue={useFilterApplyButton ? filterValues[filter.id] : value}
            rangeLimit={filter.rangeLimit}
            useFilterApplyButton={useFilterApplyButton}
            setDisableApplyButton={setDisableApplyButton}
          />
        </>
      )
    }

    case 'HIDDEN': {
      return null
    }

    default: {
      return (
        <S.Input
          label={filter.label}
          defaultValue={value}
          onChange={handleChange(filter.id)}
          data-pho="filterMenuDefaultInput"
        />
      )
    }
  }
}

function InputFilter({
  label,
  defaultValue,
  submitValue,
}: {
  label: string
  defaultValue: any
  submitValue: Function
}) {
  const inputRef = React.useRef() as any
  const [value, setValue] = React.useState(defaultValue)

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setValue(event.target.value)
  }

  function handlePressEnter(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === 'Enter') {
      handleSubmit()
    }
  }

  function handleSubmit() {
    if (isEmpty(value)) {
      submitValue(null)
    } else {
      submitValue(value)
    }

    inputRef?.current?.blur()
  }

  return (
    <FormControl component="fieldset">
      <FormLabel>{label}</FormLabel>
      <S.StyledTextField
        inputRef={inputRef}
        onMouseDown={e => e.stopPropagation()}
        id="filter-input"
        variant="outlined"
        value={value}
        onChange={handleChange}
        onKeyDown={handlePressEnter}
        placeholder="Search"
        size="small"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Icon name="Search" style={{ cursor: 'pointer' }} onClick={() => handleSubmit()} />
            </InputAdornment>
          ),
        }}
      />
    </FormControl>
  )
}

export default FilterMenuContent
