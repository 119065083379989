import { gql } from '@apollo/client'

export const UPDATE_CATEGORY = gql`
  mutation (
    $_id: String!
    $category: String
    $subcategory: String
    $description: String
    $applicationIDs: [String!]!
    $isArchived: Boolean
    $entityID: String!
    $parent: String
  ) {
    updateCategory(
      _id: $_id
      category: $category
      subcategory: $subcategory
      description: $description
      applicationIDs: $applicationIDs
      isArchived: $isArchived
      entityID: $entityID
      parent: $parent
    ) {
      _id
      applicationIDs
      category
      parent
      subcategory
      description
      isArchived
    }
  }
`

export const UPDATE_FEEDBACK_ONE = gql`
  mutation (
    $_id: String!
    $entityID: String!
    $useForRetrain: Boolean
    $isReviewed: Boolean
    $awsCategories: [String!]
    $prismAppliedCategories: [String!]
    $prismAppliedManualCategories: [String!]
    $isFlagged: Boolean
    $isHidden: Boolean
    $trainingCategories: [String!]
    $notes: String
    $reroutedCategories: [String!]
    $rerouteSentCategories: [String!]
  ) {
    updateFeedbackOne(
      _id: $_id
      entityID: $entityID
      awsCategories: $awsCategories
      prismAppliedCategories: $prismAppliedCategories
      prismAppliedManualCategories: $prismAppliedManualCategories
      useForRetrain: $useForRetrain
      isReviewed: $isReviewed
      isFlagged: $isFlagged
      isHidden: $isHidden
      trainingCategories: $trainingCategories
      notes: $notes
      reroutedCategories: $reroutedCategories
      rerouteSentCategories: $rerouteSentCategories
    ) {
      _id
      source
      visitId
      timestamp
      appName
      appType
      verbatim
      CSAT
      CES
      NPS
      SR
      footprint
      browser
      os
      deviceType
      deviceModel
      deviceFormFactor
      loggedInStatus
      starRatingExternal
      starRating
      awsCategories {
        _id
        category
        subcategory
        description
      }
      awsCategoriesScore
      userSelectedCategory
      awsSentiment {
        negative
        positive
        mixed
        neutral
      }
      prismAppliedCategories {
        _id
        category
        subcategory
        description
      }
      prismAppliedManualCategories {
        _id
        manualCategory
        category
        description
        isArchived
      }
      reroutedCategories {
        _id
        category
        subcategory
        description
      }
      awsKeywords
      useForRetrain
      isFlagged
      isReviewed
      isHidden
      notes
      surveyID
      rerouteSentCategories {
        _id
        category
        subcategory
        description
      }
    }
  }
`

export const DELETE_TRAINING_DATA_MANY = gql`
  mutation ($ids: [String!]!   $entityID: String!) {
    deleteManyTrainingData(
      _ids: $ids
      entityID: $entityID
    )
  }
`

export const INSERT_TRAINING_DATA_MANY = gql`
  mutation (
    $applicationIDs: [String!]!
    $categoryIDs: [String!]!
    $csvData: [TrainingDataCSVInsert!]!
    $entityID: String!
  ) {
    insertTrainingData(
      applicationIDs: $applicationIDs
      categoryIDs: $categoryIDs
      csvData: $csvData
      entityID: $entityID
    )
  }
`

export const INSERT_CATEGORY_UPDATED = gql`
  mutation (
    $applicationIDs: [String!]!
    $category: String!
    $description: String
    $entityID: String!
    $parent: String
  ) {
    insertCategory(
      applicationIDs: $applicationIDs
      category: $category
      description: $description
      entityID: $entityID
      parent: $parent
    ) {
      _id
      category
    }
  }
`

export const INSERT_CATEGORY = gql`
  mutation (
    $_id: String
    $applicationIDs: [String!]
    $category: String!
    $description: String
    $entityID: String!
    $parent: String
  ) {
    insertCategory(
      _id: $_id
      applicationIDs: $applicationIDs
      category: $category
      description: $description
      entityID: $entityID
      parent: $parent
    ) {
      _id
      category
      applicationIDs
      description
      parent
      isMLTrained
    }
  }
`

export const INSERT_MANUAL_CATEGORY = gql`
  mutation (
    $_id: String
    $applicationIDs: [String!]
    $category: String!
    $manualCategory: String!
    $description: String
    $entityID: String!
  ) {
    insertManualCategory(
      _id: $_id
      applicationIDs: $applicationIDs
      category: $category
      manualCategory: $manualCategory
      description: $description
      entityID: $entityID
    ) {
      _id
      category
      manualCategory
      applicationIDs
      description
    }
  }
`

export const INSERT_USER = gql`
  mutation (
    $authID: String!
    $firstName: String!
    $lastName: String!
    $email: String!
    $role: String!
    $isArchived: Boolean
  ) {
    insertUser(
      authID: $authID
      firstName: $firstName
      lastName: $lastName
      email: $email
      role: $role
      isArchived: $isArchived
    ) {
      _id
      authID
      firstName
      lastName
      email
      role
      isArchived
    }
  }
`

export const UPDATE_USER = gql`
  mutation (
    $_id: String!
    $authID: String!
    $firstName: String!
    $lastName: String!
    $email: String!
    $role: String!
    $isArchived: Boolean
  ) {
    updateUser(
      _id: $_id
      authID: $authID
      firstName: $firstName
      lastName: $lastName
      email: $email
      role: $role
      isArchived: $isArchived
    ) {
      _id
      authID
      firstName
      lastName
      email
      role
      isArchived
    }
  }
`

export const INSERT_USER_PERMISSION = gql`
  mutation (
    $userID: String!
    $authID: String!
    $entityID: String!
    $read: Boolean!
    $write: Boolean!
    $isArchived: Boolean!
  ) {
    insertUserPermission(
      userID: $userID
      authID: $authID
      entityID: $entityID
      read: $read
      write: $write
      isArchived: $isArchived
    ) {
      _id
      authID
      entityID
      read
      write
      isArchived
    }
  }
`

export const UPDATE_USER_PERMISSION = gql`
  mutation (
    $_id: String!
    $authID: String!
    $entityID: String!
    $read: Boolean!
    $write: Boolean!
    $isArchived: Boolean!
  ) {
    updateUserPermission(
      _id: $_id
      authID: $authID
      entityID: $entityID
      read: $read
      write: $write
      isArchived: $isArchived
    ) {
      _id
      authID
      entityID
      read
      write
      isArchived
    }
  }
`

export const INSERT_COLUMN_GROUP = gql`
  mutation ($authID: String!, $columnGroup: ColumnGroupInput!) {
    insertColumnGroup(authID: $authID, columnGroup: $columnGroup) {
      _id
      columnGroups {
        _id
        userID
        name
        columns {
          name
          label
          orderNumber
        }
      }
    }
  }
`

export const UPDATE_COLUMN_GROUP = gql`
  mutation ($authID: String!, $columnGroup: ColumnGroupUpdateInput!) {
    updateColumnGroup(authID: $authID, columnGroup: $columnGroup) {
      _id
      columnGroups {
        _id
        userID
        name
        columns {
          name
          label
          orderNumber
        }
      }
    }
  }
`

export const DELETE_COLUMN_GROUP = gql`
  mutation ($authID: String!, $_id: String!) {
    deleteColumnGroup(authID: $authID, _id: $_id) {
      _id
      columnGroups {
        _id
        userID
        name
        columns {
          name
          label
        }
      }
    }
  }
`

export const UPDATE_USER_DEFAULT_COLUMN_GROUP = gql`
  mutation ($_id: String!, $columnGroupID: String!) {
    updateUserDefaultColumnGroup(_id: $_id, columnGroupID: $columnGroupID) {
      _id
    }
  }
`

export const UPDATE_FEEDBACK_BATCH = gql`
  mutation (
    $ids: [String!]
    $entityID: String!
    $useForRetrain: Boolean
    $isReviewed: Boolean
    $prismAppliedCategories: [String!]
    $prismAppliedManualCategories: [String!]
    $isFlagged: Boolean
    $isHidden: Boolean
    $trainingCategories: [String!]
    $reroutedCategories: [String!]
    $keepExistingData: Boolean
    $awsReroutes: [Reroutes!]
    $individualCategoryReroutes: [Reroutes!]
    $rerouteSentCategories: [String!]
  ) {
    updateFeedbackBatch(
      ids: $ids
      entityID: $entityID
      prismAppliedCategories: $prismAppliedCategories
      prismAppliedManualCategories: $prismAppliedManualCategories
      useForRetrain: $useForRetrain
      isReviewed: $isReviewed
      isFlagged: $isFlagged
      isHidden: $isHidden
      trainingCategories: $trainingCategories
      reroutedCategories: $reroutedCategories
      keepExistingData: $keepExistingData
      awsReroutes: $awsReroutes
      individualCategoryReroutes: $individualCategoryReroutes
      rerouteSentCategories: $rerouteSentCategories
    ) {
      _id
      source
      visitId
      timestamp
      appName
      appType
      verbatim
      CSAT
      CES
      NPS
      SR
      footprint
      browser
      os
      deviceType
      deviceModel
      deviceFormFactor
      loggedInStatus
      starRatingExternal
      starRating
      awsCategories {
        _id
        category
        subcategory
        description
      }
      awsCategoriesScore
      userSelectedCategory
      awsSentiment {
        negative
        positive
        mixed
        neutral
      }
      prismAppliedCategories {
        _id
        category
        subcategory
        description
      }
      prismAppliedManualCategories {
        _id
        manualCategory
        category
        description
        isArchived
      }
      reroutedCategories {
        _id
        category
        subcategory
        description
      }
      awsKeywords
      useForRetrain
      isFlagged
      isReviewed
      isHidden
      notes
      surveyID
      rerouteSentCategories {
        _id
        category
        subcategory
        description
      }
    }
  }
`

export const UPDATE_MANUAL_CATEGORY = gql`
  mutation (
    $_id: String!
    $category: String!
    $manualCategory: String!
    $description: String
    $applicationIDs: [String!]!
    $isArchived: Boolean
    $entityID: String!
  ) {
    updateManualCategory(
      _id: $_id
      category: $category
      manualCategory: $manualCategory
      description: $description
      applicationIDs: $applicationIDs
      isArchived: $isArchived
      entityID: $entityID
    )
  }
`
